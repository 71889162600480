// Function to check if the user has permission for a specific section and action 
// Example: check(ROLES.ADMIN, 'VT', 'affecter') => true
export function check(userRole, section, action) {
  if (rolesGranted[section] && rolesGranted[section][action]) {
    return rolesGranted[section][action]?.includes(userRole);
  }
  return false;
}

// Function to check if the user has permission for a specific section and action by status 
// Example: checkPermissionByStatus(ROLES.ADMIN, 'DEVIS_FAISABILITE', 'terminer', 'valider') => true
export function checkPermissionByStatus(userRole, section, status, action) {
  if (!!rolesGranted[section] && !!rolesGranted[section]['status_actions'] && !!status) {
    return rolesGranted[section]['status_actions'][status][action]?.includes(userRole);
  }
  return false;
}

// list of roles
export const ROLES = {
  SUPER_ADMIN: "superadmin",
  ADMIN: "admin",
  ADV: "ADV",
  CHEF: "Chef équipe",
  CHEF_ING: "chefIngenieur",
  PROSPECTEUR: "Prospecteur",
  TECHNICIEN: "technicien",
  INGENIEUR: "ingenieur",
  VENDEUR: "vendeur",
}

export const rolesGranted = {
  // Faisabilité Borne
  BORNE_FAISABILITE:{
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING, ROLES.INGENIEUR, ROLES.TECHNICIEN,ROLES.PROSPECTEUR],
    consulter_prix: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
    
    // list des status and their buttons
    status_actions: {
      valider: {
        // list des buttons
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
        
      },
      pre_valider: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      en_attend: {
        // list des buttons
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [],
        create_VI:[],
        renitialiser: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        envoyer_signiature : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],

      },
      refusee: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
      annuler: {
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [],
        signer : [],
        envoyer_signiature : [],
      },
      envoyer_signiature: {
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      signer: {
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
     
    }

  },

  // Faisabilité Devis
  DEVIS_FAISABILITE: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING, ROLES.INGENIEUR, ROLES.TECHNICIEN,ROLES.PROSPECTEUR],
    consulter_prix: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
    
    // list des status and their buttons
    status_actions: {
      valider: {
        // list des buttons
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
        
      },
      pre_valider: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      en_attend: {
        // list des buttons
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [],
        create_VI:[],
        renitialiser: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        envoyer_signiature : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],

      },
      refusee: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
      annuler: {
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [],
        signer : [],
        envoyer_signiature : [],
      },
      envoyer_signiature: {
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      signer: {
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
        create_VT: [ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        create_VI:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
     
    }
  },
  // APD Devis
  DEVIS_APD: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING, ROLES.INGENIEUR, ROLES.TECHNICIEN,ROLES.PROSPECTEUR],
    consulter_prix: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
    
    // list des status and their buttons
    status_actions: {
      valider: {
        // list des buttons
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        envoyer_signiature : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        
      },
      pre_valider: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      en_attend: {
        // list des buttons
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        envoyer_signiature : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],

      },
      refusee: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [],
        create_avenant:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
      annuler: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [],
        create_avenant:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [],
        signer : [],
        envoyer_signiature : [],
      },
      envoyer_signiature: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      signer: {
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [],
        pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
     
    }
  },
  // Avenant Devis
  DEVIS_AVENANT: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING, ROLES.INGENIEUR, ROLES.TECHNICIEN,ROLES.PROSPECTEUR],
    consulter_prix: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
    
    // list des status and their buttons
    status_actions: {
      valider: {
        // list des buttons
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        envoyer_signiature : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        
      },
      pre_valider: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      en_attend: {
        // list des buttons
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [],
        create_avenant:[],
        renitialiser: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        envoyer_signiature : [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],

      },
      refusee: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [],
        create_avenant:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
      annuler: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [],
        create_avenant:[],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [],
        signer : [],
        envoyer_signiature : [],
      },
      envoyer_signiature: {
        valider: [],
        refusee: [],
        pre_validation: [],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],

      },
      signer: {
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [],
        pre_validation: [ROLES.CHEF_ING, ROLES.INGENIEUR],
        create_VT: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_avenant:[ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.ADV, ROLES.CHEF],
        renitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        signer : [],
        envoyer_signiature : [],
      },
     
    }
  },
  // Visite Technique
  VT: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    supprimer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    affecter_ing: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    envelve_ing: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    affecter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR,ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.CHEF_ING,ROLES.INGENIEUR],
    modification: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    // list des status and their buttons
    status_actions: {
      valider: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [],
        create_VI: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR],
      },
      pre_valider: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI: [],
      },
      terminer: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_valider: [ROLES.CHEF_ING,ROLES.INGENIEUR],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI: [],
      },
      en_cours: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI: [],
      },
      en_attend: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [],
        create_VI: [],
      },
      annuler: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI: [],
      },
      refusee: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [],
        refusee: [],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_VI: [],
      },
    }
  },
  // Visite Installation
  VI: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    supprimer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    affecter_ing: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    envelve_ing: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    affecter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR,ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.CHEF_ING,ROLES.INGENIEUR],
    modification: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    // list des status and their buttons
    status_actions: {
      valider: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [],
      },
      pre_valider: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      terminer: {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        pre_valider: [ROLES.CHEF_ING,ROLES.INGENIEUR],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      en_cours: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      en_attend: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [],
      },
      annuler: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      refusee: {
        // list des buttons
        valider: [],
        pre_valider: [],
        annuler: [],
        refusee: [],
        reinitialiser: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
    }
  },
  // Demande de Cotation
  DC: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR,],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING, ROLES.PROSPECTEUR, ROLES.INGENIEUR, ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    // list des status and their buttons
    status_actions: {
      // en attente
      '0': {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_devis: [],
        annuler: [],
        acd_primer: [],
        // estimation: [],
      },
      // valider
      '1': {
        // list des buttons
        // valider = accepter
        valider: [],
        refusee: [],
        create_devis: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // refusee
      '2': {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // erreur technique
      '3': {
        // list des buttons
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],

      },
      // en traitement
      '4': {
        // list des buttons
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [],
        acd_primer: [],
        estimation: [],
      },
      // résilié
      '5': {
        // list des buttons
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // annuler
      '6': {
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [],
        acd_primer: [],
        estimation: [],
      }, 
      // acd primer
      '7': {
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [],
        acd_primer: [],
        estimation: [],
      },
      // téléopérable
      '8': {
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      // Aucune mesure trouvée sur ce point
      '9': {
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      // Aucune mesure trouvée sur ce point
      '10': {
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // Aucune mesure trouvée sur ce point
      '11': {
        valider: [],
        refusee: [],
        create_devis: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
    }

  },
  // Demande de Cotation solar
  DCS: {
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.PROSPECTEUR,],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING, ROLES.PROSPECTEUR, ROLES.INGENIEUR, ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    // list des status and their buttons
    status_actions: {
      // en attente
      '0': {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // valider
      '1': {
        // list des buttons
        // valider = accepter
        valider: [],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // refusee
      '2': {
        // list des buttons
        valider: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // erreur technique
      '3': {
        // list des buttons
        valider: [],
        refusee: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],

      },
      // en traitement
      '4': {
        // list des buttons
        valider: [],
        refusee: [],
        annuler: [],
        acd_primer: [],
        estimation: [],
      },
      // résilié
      '5': {
        // list des buttons
        valider: [],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // annuler
      '6': {
        valider: [],
        refusee: [],
        annuler: [],
        acd_primer: [],
        estimation: [],
      }, 
      // acd primer
      '7': {
        valider: [],
        refusee: [],
        annuler: [],
        acd_primer: [],
        estimation: [],
      },
      // téléopérable
      '8': {
        valider: [],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      // Aucune mesure trouvée sur ce point
      '9': {
        valider: [],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
      // Aucune mesure trouvée sur ce point
      '10': {
        valider: [],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [],
      },
      // Aucune mesure trouvée sur ce point
      '11': {
        valider: [],
        refusee: [],
        annuler: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        acd_primer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
        estimation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
      },
    }

  },
  // the rest of roles in the bottom not finished yet
  CALENDAR: {
    affecter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    supprimer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR,ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR],
    modification: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    status_actions: {},
  },
  EQUIPE: {
    affecter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    supprimer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR,ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR],
    modification: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    status_actions: {},
  },
  COMPTE: {
    affecter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    envelve: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF, ROLES.CHEF_ING],
    ajouter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    supprimer: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    consulter: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR,ROLES.TECHNICIEN],
    validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    pre_validation: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF,ROLES.CHEF_ING,ROLES.INGENIEUR],
    modification: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ADV, ROLES.CHEF],
    status_actions: {},
  },
}