<template>
  <div>
    <!-- Modal Add Image and Download PDF -->
    <div
      class="modal fade"
      id="modalAddImageAndDownloadPdf"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddImageAndDownloadPdfLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddImageAndDownloadPdfLabel">
              Télécharger une Présentation PDF
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <v-radio-group v-model="showBudget">
              <v-radio label="Oui" :value="true"></v-radio>
              <v-radio label="Non" :value="false"></v-radio>
            </v-radio-group>

            <v-btn class="button-download-pdf" :color="$colors[1]" @click="previewPdf()">
              <span class="white--text">preview</span>
            </v-btn>
            <v-btn class="button-download-pdf" :color="$colors[0]" @click="generatePdf()">
              <v-icon class="white--text">mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- ******************************* -->
    <!-- button toggle modal image input -->
    <!-- ******************************* -->
    <v-btn
      class="ml-4"
      data-toggle="modal"
      data-target="#modalAddImageAndDownloadPdf"
      :color="$colors[0]"
    >
      <v-icon class="white--text">mdi-download-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFDocument, rgb } from "pdf-lib";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    pdfData: Object,
    compteur: String,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      showBudget: false,

      // page De Garde
      pageDeGardeTitle: "",
      RespNom: "",
      RespPrenom: "",
      RespFonction: ``,
      RespEmail: "",
      RespTel: "",
      ClientNom: "",
      ClientPrenom: "",
      ClientSociete: "",
      ClientTel: "",
      // page 4
      consommationGlobale: 0,
      productionGlobale: 0,
      autoConsommationCollectifGlobale: 0,
      autoConsommationIndividuelle: 0,
      surplusGlobale: 0,
      nbrHeuresCircuit: 0,
      // page total budgets d'energie
      budgetEnergieInitiale: 0,
      budgetEnergieApres: 0,
      budgetEnergieAutoConsoCollectif: 0,
      budgetEnergieFournisseurTurpe: 0,
      // table detail
      tableConsoProd: [],
    };
  },
  watch: {
    pdfData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.fillPropsData();
      },
    },
  },
  methods: {
    fillPropsData() {
      // page de garde
      this.pageDeGardeTitle = this.pdfData.pageDeGardeTitle;
      this.RespNom = this.pdfData.RespNom;
      this.RespPrenom = this.pdfData.RespPrenom;
      this.RespFonction = this.pdfData.RespFonction;
      this.RespEmail = this.pdfData.RespEmail;
      this.RespTel = this.pdfData.RespTel;
      this.ClientNom = this.pdfData.ClientNom;
      this.ClientPrenom = this.pdfData.ClientPrenom;
      this.ClientSociete = this.pdfData.ClientSociete;
      this.ClientTel = this.pdfData.ClientTel;
      // page 4
      this.consommationGlobale = this.pdfData.consommationGlobale;
      this.productionGlobale = this.pdfData.productionGlobale;
      this.autoConsommationCollectifGlobale = this.pdfData.autoConsommationCollectifGlobale;
      this.autoConsommationIndividuelle = this.pdfData.autoConsommationIndividuelle;
      this.surplusGlobale = this.pdfData.surplusGlobale;
      this.nbrHeuresCircuit = this.pdfData.nbrHeuresCircuit;
      // page total budgets d'energie
      this.budgetEnergieInitiale = this.pdfData.budgetEnergieInitiale;
      this.budgetEnergieApres = this.pdfData.budgetEnergieApres;
      this.budgetEnergieAutoConsoCollectif = this.pdfData.budgetEnergieAutoConsoCollectif;
      this.budgetEnergieFournisseurTurpe = this.pdfData.budgetEnergieFournisseurTurpe;
      // table detail
      this.tableConsoProd = this.pdfData.tableConsoProd;
    },
    // format pdf numbers
    to_fixed2LocaleString(num) {
      return num != null && num >= 0
        ? Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString()
        : "--";
    },

    // view pdf as html
    async previewPdf() {
      console.log(this.showBudget);
    },
    // pdf generation
    async generatePdf() {
      console.log("generating pdf...");

      const pageDeGardeDoc = await this.pageDeGardeFiller();
      const page2Doc = await this.pageNotreApprocheFiller();
      const page3Doc = await this.pageDetailProcessusFiller();
      const page4Doc = await this.pageInfoGlobaleFiller();
      const page6Doc = await this.pageTotaleDesBudgetsFiller();
      const page5Doc = await this.pageTableConsoProdFiller();
      const page5Doc_2 = await this.page2TableConsoProdFiller();
      const page7Doc = await this.pageTableBudgetsFiller();
      const pageFinaleDoc = await this.pageFinaleFiller();

      console.log("loaded and filled all pdf forms...");

      let mergedPdf = await PDFDocument.create();
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageDeGardeDoc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page2Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page3Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page4Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page6Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page5Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page5Doc_2);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page7Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageFinaleDoc);

      console.log("marged all pdf...");

      // save pdf
      const filledPdfBytes = await mergedPdf.save();
      console.log("Downloading the filled PDF...");
      // Download the filled PDF
      const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Présentation.pdf";
      link.click();
    },
    // to merge pdf pages
    addPagesToPdf: async function (mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(donorPdf, donorPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
    // Page: page de garde
    async pageDeGardeFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/solar-collectif/Annexe-1.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();

      // Get all fields in the PDF by their names
      const pageDeGardeTitle = form.getTextField("PageDeGarde-title");
      const RespName = form.getTextField("PageDeGarde-RespName");
      const RespFonction = form.getTextField("PageDeGarde-RespFonction");
      const RespEmail = form.getTextField("PageDeGarde-RespEmail");
      const RespTel = form.getTextField("PageDeGarde-RespTel");
      const ClientName = form.getTextField("PageDeGarde-ClientName");
      const ClientSociete = form.getTextField("PageDeGarde-ClientSociete");
      const ClientTel = form.getTextField("PageDeGarde-ClientTel");

      // Fill in the basic info fields
      pageDeGardeTitle.setText(this.pageDeGardeTitle.toString());
      RespName.setText(this.RespPrenom.toString() + this.RespNom.toString());
      RespFonction.setText(this.RespFonction.toString());
      RespEmail.setText(this.RespEmail.toString());
      RespTel.setText(this.RespTel.toString());
      ClientName.setText(this.ClientPrenom.toString() + this.ClientNom.toString());
      ClientSociete.setText(this.ClientSociete.toString());
      ClientTel.setText(this.ClientTel.toString());

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Notre approche
    async pageNotreApprocheFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/solar-collectif/Annexe-2.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      return pdfDoc;
    },
    // Page: Detail du processus
    async pageDetailProcessusFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/solar-collectif/Annexe-3.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      return pdfDoc;
    },
    // Page: Informations Globale (Numbers)
    async pageInfoGlobaleFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/solar-collectif/Annexe-4.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();

      // Get all fields in the PDF by their names
      const consommation_globale = form.getTextField("consommation_globale");
      const production_globale = form.getTextField("production_globale");
      const auto_conso_collectif_globale = form.getTextField(
        "auto_conso_collectif_globale"
      );
      const auto_conso_individuelle = form.getTextField("auto_conso_individuelle");
      const surplus_globale = form.getTextField("surplus_globale");
      const nbr_heures_circuit = form.getTextField("nbr_heures_circuit");

      // Fill in the basic info fields
      const ConsoG = `${this.to_fixed2LocaleString(this.consommationGlobale)} (KWh)`;
      const ProdG = `${this.to_fixed2LocaleString(this.productionGlobale)} (KWh)`;
      const AConsoCG = `${this.to_fixed2LocaleString(
        this.autoConsommationCollectifGlobale
      )} (KWh)`;
      const AConsoIndv = `${this.to_fixed2LocaleString(
        this.autoConsommationIndividuelle
      )} (KWh)`;
      const SG = `${this.to_fixed2LocaleString(this.surplusGlobale)} (KWh)`;
      const NbrHeuresCircuit = `${this.nbrHeuresCircuit} (Heure)`;

      consommation_globale.setText(ConsoG);
      production_globale.setText(ProdG);
      auto_conso_collectif_globale.setText(AConsoCG);
      auto_conso_individuelle.setText(AConsoIndv);
      surplus_globale.setText(SG);
      nbr_heures_circuit.setText(NbrHeuresCircuit);

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Liste Consommation et productions d'energie detaillé (Table multi page)
    async pageTableConsoProdFiller() {
      // // Load the PDF from the project folder
      // const pdfPath = "/pdf/solar-collectif/Annexe-5.pdf";
      // const response = await fetch(pdfPath);
      // const arrayBuffer = await response.arrayBuffer();
      // const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfDoc = await PDFDocument.create();

      // create table pages with jsPDF
      const rowsPerPage = 8;
      const totalPages = Math.ceil(this.tableConsoProd.length / rowsPerPage);
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        // create new jsPDF page
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
        });
        const pageSize = doc.internal.pageSize;

        // draw page logo eneffic image
        let logoPageEnefficURL = "/pdf/solar-collectif/images/eneffic.png";
        doc.addImage(logoPageEnefficURL, "PNG", 28, 28, 60, 10);

        // draw page title text
        doc.setFillColor(1, 246, 187);
        doc.rect(pageSize.width / 2 - 80, 52, 95, 6, "F");
        let strPageTitle = "Liste des consommations et";
        doc.setFontSize(17);
        doc.setTextColor(20, 50, 79);
        doc.text(strPageTitle, pageSize.width / 2, 55, { align: "center" });
        let strPageTitle2 = "productions d’énergie détaillée";
        doc.setFontSize(17);
        doc.setTextColor(20, 50, 79);
        doc.text(strPageTitle2, pageSize.width / 2, 72, { align: "center" });

        // draw footer bottom two green sqaures
        const squareWidth = 32;
        doc.setFillColor(1, 246, 187);
        doc.rect(
          pageSize.width - squareWidth * 2,
          pageSize.height - squareWidth,
          squareWidth,
          squareWidth,
          "F"
        ); // filled square
        doc.setFillColor(1, 246, 187);
        doc.rect(
          pageSize.width - squareWidth,
          pageSize.height - squareWidth * 2,
          squareWidth,
          squareWidth,
          "F"
        ); // filled square

        // draw footer text
        const footerFontSize = 8;
        let str1Footer = "ENEFFIC - 2 Allée du Levant 34970 Lattes";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str1Footer, pageSize.width / 2, pageSize.height - 30, {
          align: "center",
        });
        let str2Footer =
          "Téléphone : +33 (0)1 41 22 1 4 10 - E-mail : contact@eneffic.fr";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str2Footer, pageSize.width / 2, pageSize.height - 22, {
          align: "center",
        });
        let str3Footer = "SIREN : 951 979 798";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str3Footer, pageSize.width / 2, pageSize.height - 14, {
          align: "center",
        });

        // draw table
        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, this.tableConsoProd.length);
        let copiedData = this.tableConsoProd.slice(startIndex, endIndex);
        let currentData = JSON.parse(JSON.stringify(copiedData));
        for (let i = 0; i < currentData.length; i++) {
          const element = currentData[i];
          element["Consommation"] = `${this.to_fixed2LocaleString(element["Consommation"])} KWh`;
          element["Production"] = `${this.to_fixed2LocaleString(element["Production"])} KWh`;
          element["Reste_A_Acheter"] = `${this.to_fixed2LocaleString(element["Reste_A_Acheter"])} KWh`;
        }
        const data = [];
        doc.autoTable({
          margin: {
            top: 100,
            left: 25,
          },
          body: currentData,
          columns: [
            {
              header: "Compteur",
              dataKey: "Numero_Compteur",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Type Production",
              dataKey: "typeProduction",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Consommation Initiale",
              dataKey: "Consommation",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Production",
              dataKey: "Production",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Consommation Fournisseur",
              dataKey: "Reste_A_Acheter",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          bodyStyles: {
            // fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 50,
          },
          headStyles: {
            // fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 4,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80, fillColor: "#ffffff" },
            1: { cellWidth: 80, fillColor: "#ffffff" },
            2: { cellWidth: 80, fillColor: "#14DAF5" },
            3: { cellWidth: 80, fillColor: "#01F6BB" },
            4: { cellWidth: 80, fillColor: "#D2FFEE" },
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (data.row.index >= 0 && data.section == "body" && data.column.index > 1) {
              // console.log(data, "DATA CELL PDF");
              // data.cell.text[0] = `${data.cell.raw.toLocaleString("fr-FR")} KWh`;
              // console.log(data, "DATA CELL PDF");
            }
          },
        });

        // add jsPDF generated page to the pdf
        const arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await pdfDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        pdfDoc.addPage(copiedPage);
        if (endIndex === this.tableConsoProd.length) {
          break; // stop for loop when we reached the maximum data
        }
      }

      return pdfDoc;
    },
    // Page2: Liste Consommation et productions d'energie detaillé (Table multi page)
    async page2TableConsoProdFiller() {
      // // Load the PDF from the project folder
      // const pdfPath = "/pdf/solar-collectif/Annexe-5.pdf";
      // const response = await fetch(pdfPath);
      // const arrayBuffer = await response.arrayBuffer();
      // const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfDoc = await PDFDocument.create();

      // create table pages with jsPDF
      const rowsPerPage = 8;
      const totalPages = Math.ceil(this.tableConsoProd.length / rowsPerPage);
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        // create new jsPDF page
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
        });
        const pageSize = doc.internal.pageSize;

        // draw page logo eneffic image
        let logoPageEnefficURL = "/pdf/solar-collectif/images/eneffic.png";
        doc.addImage(logoPageEnefficURL, "PNG", 28, 28, 60, 10);

        // draw page title text
        doc.setFillColor(1, 246, 187);
        doc.rect(pageSize.width / 2 - 80, 52, 95, 6, "F");
        let strPageTitle = "Liste des consommations et";
        doc.setFontSize(17);
        doc.setTextColor(20, 50, 79);
        doc.text(strPageTitle, pageSize.width / 2, 55, { align: "center" });
        let strPageTitle2 = "productions d’énergie détaillée";
        doc.setFontSize(17);
        doc.setTextColor(20, 50, 79);
        doc.text(strPageTitle2, pageSize.width / 2, 72, { align: "center" });

        // draw footer bottom two green sqaures
        const squareWidth = 32;
        doc.setFillColor(1, 246, 187);
        doc.rect(
          pageSize.width - squareWidth * 2,
          pageSize.height - squareWidth,
          squareWidth,
          squareWidth,
          "F"
        ); // filled square
        doc.setFillColor(1, 246, 187);
        doc.rect(
          pageSize.width - squareWidth,
          pageSize.height - squareWidth * 2,
          squareWidth,
          squareWidth,
          "F"
        ); // filled square

        // draw footer text
        const footerFontSize = 8;
        let str1Footer = "ENEFFIC - 2 Allée du Levant 34970 Lattes";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str1Footer, pageSize.width / 2, pageSize.height - 30, {
          align: "center",
        });
        let str2Footer =
          "Téléphone : +33 (0)1 41 22 1 4 10 - E-mail : contact@eneffic.fr";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str2Footer, pageSize.width / 2, pageSize.height - 22, {
          align: "center",
        });
        let str3Footer = "SIREN : 951 979 798";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str3Footer, pageSize.width / 2, pageSize.height - 14, {
          align: "center",
        });

        // draw table
        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, this.tableConsoProd.length);
        let copiedData = this.tableConsoProd.slice(startIndex, endIndex);
        let currentData = JSON.parse(JSON.stringify(copiedData));
        for (let i = 0; i < currentData.length; i++) {
          const element = currentData[i];
          element["sommeAutoConsommerIndividuelle"] = `${this.to_fixed2LocaleString(element["sommeAutoConsommerIndividuelle"])} KWh`;
          element["Auto_Consommation_Collectif"] = `${this.to_fixed2LocaleString(element["Auto_Consommation_Collectif"])} KWh`;
          element["Auto_Consommation_Total"] = `${this.to_fixed2LocaleString(element["Auto_Consommation_Total"])} KWh`;
        }
        const data = [];

        doc.autoTable({
          margin: {
            top: 100,
            left: 25,
          },
          body: currentData,
          columns: [
            {
              header: "Compteur",
              dataKey: "Numero_Compteur",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Type Production",
              dataKey: "typeProduction",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Autoconsommation Individuelle",
              dataKey: "sommeAutoConsommerIndividuelle",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Autoconsommation Collective",
              dataKey: "Auto_Consommation_Collectif",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Autoconsommation Collective Global",
              dataKey: "Auto_Consommation_Total",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          bodyStyles: {
            // fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 50,
          },
          headStyles: {
            // fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 4,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80, fillColor: "#ffffff" },
            1: { cellWidth: 80, fillColor: "#ffffff" },
            2: { cellWidth: 80, fillColor: "#14DAF5" },
            3: { cellWidth: 80, fillColor: "#01F6BB" },
            4: { cellWidth: 80, fillColor: "#D2FFEE" },
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (data.row.index >= 0 && data.section == "body" && data.column.index > 1) {
              // console.log(data, "DATA CELL PDF");
              // data.cell.text[0] = `${data.cell.raw.toLocaleString("fr-FR")} KWh`;
              // console.log(data, "DATA CELL PDF");
            }
          },
        });

        // add jsPDF generated page to the pdf
        const arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await pdfDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        pdfDoc.addPage(copiedPage);
        if (endIndex === this.tableConsoProd.length) {
          break; // stop for loop when we reached the maximum data
        }
      }

      return pdfDoc;
    },
    // Page: Totale des budgets d'energie
    async pageTotaleDesBudgetsFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/solar-collectif/Annexe-6.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();

      // Get all fields in the PDF by their names
      const budget_energie_initiale = form.getTextField("budget_energie_initiale");
      const budget_energie_apres = form.getTextField("budget_energie_apres");
      const budget_energie_auto_conso_collectif = form.getTextField(
        "budget_energie_auto_conso_collectif"
      );
      const budget_energie_frn_turpe = form.getTextField("budget_energie_frn_turpe");

      // Fill in the basic info fields
      const budgetEnergieInitiale = `${this.to_fixed2LocaleString(
        this.budgetEnergieInitiale
      )}€ / an`;
      const budgetEnergieApres = `${this.to_fixed2LocaleString(
        this.budgetEnergieApres
      )}€ / an`;
      const budgetEnergieAutoConsoCollectif = `${this.to_fixed2LocaleString(
        this.budgetEnergieAutoConsoCollectif
      )}€ / an`;
      const budgetEnergieFournisseurTurpe = `${this.to_fixed2LocaleString(
        this.budgetEnergieFournisseurTurpe
      )}€ / an`;

      budget_energie_initiale.setText(budgetEnergieInitiale);
      budget_energie_apres.setText(budgetEnergieApres);
      budget_energie_auto_conso_collectif.setText(budgetEnergieAutoConsoCollectif);
      budget_energie_frn_turpe.setText(budgetEnergieFournisseurTurpe);

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Liste des budgets d'energie detaillé (Table multi page)
    async pageTableBudgetsFiller() {
      // // Load the PDF from the project folder
      // const pdfPath = "/pdf/solar-collectif/Annexe-7.pdf";
      // const response = await fetch(pdfPath);
      // const arrayBuffer = await response.arrayBuffer();
      // const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfDoc = await PDFDocument.create();

      // create table pages with jsPDF
      const rowsPerPage = 8;
      const totalPages = Math.ceil(this.tableConsoProd.length / rowsPerPage);
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        // create new jsPDF page
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
        });
        const pageSize = doc.internal.pageSize;

        // draw page logo eneffic image
        let logoPageEnefficURL = "/pdf/solar-collectif/images/eneffic.png";
        doc.addImage(logoPageEnefficURL, "PNG", 28, 28, 60, 10);

        // draw page title text
        doc.setFillColor(1, 246, 187);
        doc.rect(pageSize.width / 2 - 95, 52, 95, 6, "F");
        let strPageTitle = "Liste des budgets d’énergie détaillée";
        doc.setFontSize(17);
        doc.setTextColor(20, 50, 79);
        doc.text(strPageTitle, pageSize.width / 2, 55, { align: "center" });

        // draw footer bottom two green sqaures
        const squareWidth = 32;
        doc.setFillColor(1, 246, 187);
        doc.rect(
          pageSize.width - squareWidth * 2,
          pageSize.height - squareWidth,
          squareWidth,
          squareWidth,
          "F"
        ); // filled square
        doc.setFillColor(1, 246, 187);
        doc.rect(
          pageSize.width - squareWidth,
          pageSize.height - squareWidth * 2,
          squareWidth,
          squareWidth,
          "F"
        ); // filled square

        // draw footer text
        const footerFontSize = 8;
        let str1Footer = "ENEFFIC - 2 Allée du Levant 34970 Lattes";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str1Footer, pageSize.width / 2, pageSize.height - 30, {
          align: "center",
        });
        let str2Footer =
          "Téléphone : +33 (0)1 41 22 1 4 10 - E-mail : contact@eneffic.fr";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str2Footer, pageSize.width / 2, pageSize.height - 22, {
          align: "center",
        });
        let str3Footer = "SIREN : 951 979 798";
        doc.setFontSize(footerFontSize);
        doc.setTextColor(180, 180, 180);
        doc.text(str3Footer, pageSize.width / 2, pageSize.height - 14, {
          align: "center",
        });

        // draw table
        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, this.tableConsoProd.length);
        let copiedData = this.tableConsoProd.slice(startIndex, endIndex);
        let currentData = JSON.parse(JSON.stringify(copiedData));
        for (let i = 0; i < currentData.length; i++) {
          const element = currentData[i];
          element["budgetInitial"] = `${this.to_fixed2LocaleString(element["budgetInitial"])} €/an`;
          element["Budget_Energie"] = `${this.to_fixed2LocaleString(element["Budget_Energie"])} €/an`;
          element["budgetSolarAutoCollectif"] = `${this.to_fixed2LocaleString(element["budgetSolarAutoCollectif"])} €/an`;
        }
        const data = [];

        doc.autoTable({
          margin: {
            top: 100,
            left: 25,
          },
          body: currentData,
          columns: [
            {
              header: "Compteur",
              dataKey: "Numero_Compteur",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Type Production",
              dataKey: "typeProduction",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Budget Energie Initiale",
              dataKey: "budgetInitial",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Budget Energie Fournisseur",
              dataKey: "Budget_Energie",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
            {
              header: "Budget Energie ACC",
              dataKey: "budgetSolarAutoCollectif",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          bodyStyles: {
            // fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 50,
          },
          headStyles: {
            // fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 4,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80, fillColor: "#ffffff" },
            1: { cellWidth: 80, fillColor: "#ffffff" },
            2: { cellWidth: 80, fillColor: "#14DAF5" },
            3: { cellWidth: 80, fillColor: "#01F6BB" },
            4: { cellWidth: 80, fillColor: "#D2FFEE" },
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (data.row.index >= 0 && data.section == "body" && data.column.index > 1) {
              // console.log(data, "DATA CELL PDF");
              // data.cell.text[0] = `${data.cell.raw.toLocaleString("fr-FR")} €/an`;
              // console.log(data, "DATA CELL PDF");
            }
          },
        });

        // add jsPDF generated page to the pdf
        const arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await pdfDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        pdfDoc.addPage(copiedPage);
        if (endIndex === this.tableConsoProd.length) {
          break; // stop for loop when we reached the maximum data
        }
      }

      return pdfDoc;
    },
    // Page: Finale
    async pageFinaleFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/solar-collectif/Annexe-8.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      return pdfDoc;
    },
  },
};
</script>

<style scoped>
#pdf-content {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.chart-wrapper {
  width: 800px;
  height: 300px;
  background: #fff;
}
.modal-header {
  background: rgb(0, 121, 145);
  color: #fff !important;
}
.inputImageAddress::file-selector-button {
  margin-right: 20px;
  border: none;
  background: rgb(0, 121, 145);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.inputImageAddress::file-selector-button:hover {
  background: rgb(0, 121, 145);
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}
.drop-container.drag-active {
  background: #eee;
  border-color: #111;
}

.drop-container.drag-active .drop-title {
  color: #222;
}

.add-title {
  color: #747777;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.image-preview-wrapper {
  width: 100%;
  height: 230px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}

.button-download-pdf {
  width: 100%;
  height: 44px;
  margin-top: 20px;
}
</style>
