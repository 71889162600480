<template>
  
  <div class="p-4">
    <v-row v-if="loading">
      <loadingComponent v-if="loading" :dialogProps="dialogLoading" messageDialogeProps="erreur" :loadingProps="loading" :errorProps="false"/>
    </v-row>
    <div class="shadow-sm">
      <breadcrumb :listNavs="listNavs" />
    </div>
    
    <!-- dialog commentaire-->
    <v-dialog v-model="dialogComment" max-width="600px">
      <v-card>
        <v-toolbar :color="$colors[0]" dark>
          <v-card-title
            class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
            >{{
              ObjectComment?.commentaire
                ? `Commentaire général de la vente`
                : `Commentaire de refus de vente`
            }}</v-card-title
          >
        </v-toolbar>
        <v-card-text class="mt-8">
          <v-textarea
            class="text-body-2"
            readonly
            auto-grow
            outlined
            :value="
              ObjectComment?.commentaire
                ? ObjectComment.commentaire
                : ObjectComment?.commentaire_refus
            "
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-end">
          <v-btn text color="error" @click="dialogComment = false"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog refuser -->
    <v-dialog v-model="ouvrireDialogeRefuser" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Changer le segment ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea
                  :rules="[rules.required('un commentaire.')]"
                  auto-grow
                  placeholder="Laissez un commentaire"
                  rows="2"
                  row-height="20"
                  prepend-inner-icon="mdi-comment"
                  v-model="commentRefus"
                >
                </v-textarea>
              </v-col>
              <v-col class="d-flex justify-content-center" cols="12" sm="12">
                <v-btn color="blue darken-1" text @click="ouvrireDialogeRefuser = false"
                  >Annuler</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="sendActionStatus('2', typeDemandeAction, DemandeDeCotation)"
                  >Confirmer</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="segmentChange" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light">Êtes-vous sûr de bien vouloir refuser cette Demande de cotation ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-form ref="formSend" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="optionTarifaire"
                  :items="optionTarifaireChoices[segment]"
                  :rules="[rules.required('optionTarifaire est obligatoire.')]"
                  label="Option Tarifaire"
                  outlined
                  dense
                  required>
                </v-select>
                <v-text-field
                  v-model="puissance"
                  label="Puissance"
                  :rules="[rules.required('puissance est obligatoire.'), getRulesForSegment()]"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-content-center" cols="12" sm="12">
                <v-btn color="blue darken-1" text @click="cancelChangeSegment()">Annuler</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="sendChangeSegment()"
                  >Confirmer</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog refuser -->
    <v-dialog v-model="ouvrireDialogeAccepter" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de bien vouloir Accepter cette Demande de cotation
          ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-col class="d-flex justify-content-center" cols="12" sm="12">
              <v-btn color="blue darken-1" text @click="ouvrireDialogeAccepter = false"
                >Annuler</v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="sendActionStatus('1', typeDemandeAction, DemandeDeCotation)"
                >Confirmer</v-btn
              >
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
   

        
    <!-- dialog estimation courbe de charge -->
    <v-dialog width="800" v-model="openDialogConsommation">
      <template>
        <v-card title="Dialog">
          <div
            class="d-flex flex-column align-items-center"
            v-if="mesuresBindigForEstimation.mesures"
          >
            <div class="justify-content-center">
              <p class="text-center" v-if="mesuresBindigForEstimation.CDC == false">
                Consommation du {{ mesuresBindigForEstimation.mesures.nbrMonth }} mois
                disponible pour ce PDL. Créez une courbe de charge estimée à partir de
                cette consommation. Signalez tout changement avant la création de
                l'estimation.
              </p>
              <p class="text-center" v-else>
                Consommation du {{ mesuresBindigForEstimation.mesures.nbrMonth }} Jours
                disponible pour ce PDL
                <span v-if="mesuresBindigForEstimation.mesures.beginCDC != null">
                  Début: {{ mesuresBindigForEstimation.mesures.beginCDC }}
                </span>
                <span v-if="mesuresBindigForEstimation.mesures.endCDC != null">
                  Fin: {{ mesuresBindigForEstimation.mesures.endCDC }}
                </span>
                Créez une courbe de charge estimée à partir de cette consommation.
                Signalez tout changement avant la création de l'estimation.
              </p>
            </div>
            <div class="d-flex flex-row justify-content-center">
              <p v-if="mesuresBindigForEstimation.parametreCompteur == 'C5BASE'">
                nombres des mois de consommation Turpe:
                {{ mesuresBindigForEstimation.mesures.nbrMonthTURPE }} mois
                <span v-if="mesuresBindigForEstimation.mesures.lesMoisTurpe != null">
                  Les mois sont:
                  {{ mesuresBindigForEstimation.mesures.lesMoisTurpe }} </span
                ><br />
                BASE:{{ mesuresBindigForEstimation.mesures.BASETURPE }}
              </p>
              <p v-if="mesuresBindigForEstimation.parametreCompteur == 'C5HP'">
                nombres des mois de consommation Turpe:
                {{ mesuresBindigForEstimation.mesures.nbrMonthTURPE }} mois
                <span v-if="mesuresBindigForEstimation.mesures.lesMoisTurpe != null">
                  Les mois sont:
                  {{ mesuresBindigForEstimation.mesures.lesMoisTurpe }} </span
                ><br />
                HP:{{ mesuresBindigForEstimation.mesures.HPTURPE }} | HC:
                {{ mesuresBindigForEstimation.mesures.HCTURPE }}
              </p>
              <p v-if="mesuresBindigForEstimation.parametreCompteur == 'C4'">
                nombres des mois de consommation Turpe:
                {{ mesuresBindigForEstimation.mesures.nbrMonthTURPE }} mois
                <span v-if="mesuresBindigForEstimation.mesures.lesMoisTurpe != null">
                  Les mois sont:
                  {{ mesuresBindigForEstimation.mesures.lesMoisTurpe }} </span
                ><br />
                HPH:{{ mesuresBindigForEstimation.mesures.HPHTURPE }} | HCH:
                {{ mesuresBindigForEstimation.mesures.HCHTURPE }} | HPE:{{
                  mesuresBindigForEstimation.mesures.HPETURPE
                }}
                | HCE: {{ mesuresBindigForEstimation.mesures.HCETURPE }}
              </p>
              <p v-if="mesuresBindigForEstimation.parametreCompteur == 'C3'">
                nombres des mois de consommation Turpe:
                {{ mesuresBindigForEstimation.mesures.nbrMonthTURPE }} mois
                <span v-if="mesuresBindigForEstimation.mesures.lesMoisTurpe != null">
                  Les mois sont:
                  {{ mesuresBindigForEstimation.mesures.lesMoisTurpe }} </span
                ><br />
                HPH:{{ mesuresBindigForEstimation.mesures.HPHTURPE }} | HCH:
                {{ mesuresBindigForEstimation.mesures.HCHTURPE }} | HPE:{{
                  mesuresBindigForEstimation.mesures.HPETURPE
                }}
                | HCE: {{ mesuresBindigForEstimation.mesures.HCETURPE }} | POINTE:
                {{ mesuresBindigForEstimation.mesures.PointeTURPE }}
              </p>
              <br />
            </div>
          </div>
          <v-card-text>
            <v-form ref="formEstimation">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-radio-group
                    v-model="mesuresBindigForEstimation.ProfilCompteur"
                    row
                    label="Choisissez un Profil de Consommation Électrique :"
                  >
                    <v-radio label="Profil Bureautique" value="Bureau"></v-radio>
                    <v-radio label="Profil de Production" value="Production"></v-radio>
                    <v-radio label="Profil Nocturne" value="Nuit"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-radio-group
                    v-model="mesuresBindigForEstimation.ProfilWeekend"
                    row
                    label="Sélectionnez votre profil week-ends :"
                  >
                    <v-radio label="Aucun Week-end" value="noWeek"></v-radio>
                    <v-radio label="Samedi et Dimanche" value="Sam"></v-radio>
                    <v-radio label="Dimanche Seulement" value="Dim"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="6"
                  sm="5"
                  md="5"
                  lg="5"
                  xl="5"
                  v-if="mesuresBindigForEstimation.parametreCompteur == 'C5BASE'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="BASE"
                    v-model="mesuresBindigForEstimation.mesures.BASE"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="5"
                  md="5"
                  lg="5"
                  xl="5"
                  v-if="mesuresBindigForEstimation.parametreCompteur == 'C5HP'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="HP"
                    v-model="mesuresBindigForEstimation.mesures.HP"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  v-if="
                    mesuresBindigForEstimation.parametreCompteur == 'C4' ||
                    mesuresBindigForEstimation.parametreCompteur == 'C3'
                  "
                >
                  <v-text-field
                    outlined
                    dense
                    label="HPH"
                    v-model="mesuresBindigForEstimation.mesures.HPH"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="5"
                  md="5"
                  lg="5"
                  xl="5"
                  v-if="mesuresBindigForEstimation.parametreCompteur == 'C5HP'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="HC"
                    v-model="mesuresBindigForEstimation.mesures.HC"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  v-if="
                    mesuresBindigForEstimation.parametreCompteur == 'C4' ||
                    mesuresBindigForEstimation.parametreCompteur == 'C3'
                  "
                >
                  <v-text-field
                    outlined
                    dense
                    label="HCH"
                    v-model="mesuresBindigForEstimation.mesures.HCH"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  v-if="
                    mesuresBindigForEstimation.parametreCompteur == 'C4' ||
                    mesuresBindigForEstimation.parametreCompteur == 'C3'
                  "
                >
                  <v-text-field
                    outlined
                    dense
                    label="HPE"
                    v-model="mesuresBindigForEstimation.mesures.HPE"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  v-if="
                    mesuresBindigForEstimation.parametreCompteur == 'C4' ||
                    mesuresBindigForEstimation.parametreCompteur == 'C3'
                  "
                >
                  <v-text-field
                    outlined
                    dense
                    label="HCE"
                    v-model="mesuresBindigForEstimation.mesures.HCE"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  v-if="mesuresBindigForEstimation.parametreCompteur == 'C3'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="POINTE"
                    v-model="mesuresBindigForEstimation.mesures.Pointe"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <div
            class="d-flex flex-column align-items-center"
            v-if="mesuresBindigForEstimation.mesures"
          >
            <div class="d-flex flex-row justify-content-center">
              <v-btn @click="closeDialogConsommation" style="margin: 20px"
                >Annulé</v-btn
              >
              <v-btn
                @click="getEstimationCdcFromMesures"
                color="primary"
                style="margin: 20px"
                >Valider</v-btn
              >
            </div>
          </div>
        </v-card>
        <div style="width: 100%; height: 300px; background-color: #fff;">
          <canvas class="canvas" width="800" height="300" ref="myChart"></canvas>
        </div>
      </template>
    </v-dialog>
    <v-card class="p-4">
      <div style="width: 100%;overflow-x: auto;">
        <datatable
          id="table-validation-cotation"
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
          @search="rechercheData"
        >
          <template v-slot:header>
            <div style="width: 200px" class="float-end container-div mr-2">
              <select
                v-model="filterStatus"
                class="form-control"
                id="sel1"
                @change="filterByStatus"
              >
                <option value="tout">Toutes les demandes</option>
                <option value="1">acceptée</option>
                <option value="0">en attente</option>
                <option value="2">refusée</option>
              </select>
            </div>
          </template>
          <template v-slot:body>
            <tbody
              class="text-center"
              v-if="projects.length > 0 && loadingTable == false"
              v-for="(project, index) in projects"
              :key="project.id"
            >
              <tr>
                <td>{{ project.dateAjout }}</td>
                <td>{{ project.compte.nom + " " + project.compte.prenom }}</td>
                <td cl>
                  <v-tooltip v-if="project.societe.raison.length >= 20" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <small class="td-siret" v-bind="attrs" v-on="on">
                        <router-link :to="`/detail-societe/${project.societe.id}`">{{
                          project.societe.raison.substring(0, 20) + "..."
                        }}</router-link>
                      </small>
                    </template>
                    <span class="td-siret">{{ project.societe.raison }}</span>
                  </v-tooltip>
                  <small class="td-siret" v-else>
                    <router-link :to="`/detail-societe/${project.societe.id}`">{{
                      project.societe.raison
                    }}</router-link>
                  </small>
  
                  <p class="td-siret">
                    <router-link
                      class="td-siret"
                      :to="`/detail-societe/${project.societe.id}`"
                    >
                      {{ project.societe.siret }}
                    </router-link>
                  </p>
                </td>
                <td class="text-left">
                  <div style="max-width: 200px">
                    <div v-if="project.ACD.length > 0">
                      <small>
                        <a :href="project.ACD[0]" target="_blank"
                          ><span class="text-body-4 blue--text">ACD 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 1 ||
                          project.Facture.length > 0 ||
                          project.Contrat.length > 0 ||
                          project.Plan.length > 0
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div v-else-if="project.Facture.length > 0">
                      <small>
                        <a :href="project.Facture[0]" target="_blank"
                          ><span class="text-body-4 blue--text">Facture 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 0 ||
                          project.Facture.length > 1 ||
                          project.Contrat.length > 0 ||
                          project.Plan.length > 0
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div v-else-if="project.Contrat.length > 0">
                      <small>
                        <a :href="project.Contrat[0]" target="_blank"
                          ><span class="text-body-4 blue--text">Contrat 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 0 ||
                          project.Facture.length > 0 ||
                          project.Contrat.length > 1 ||
                          project.Plan.length > 0
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div v-else-if="project.Plan.length > 0">
                      <small>
                        <a :href="project.Plan[0]" target="_blank"
                          ><span class="text-body-4 blue--text">Plan 1</span></a
                        >
                      </small>
                      <v-icon
                        v-if="
                          project.ACD.length > 0 ||
                          project.Facture.length > 0 ||
                          project.Contrat.length > 0 ||
                          project.Plan.length > 1
                        "
                        data-toggle="collapse"
                        :data-target="'#multiCollapseFile' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseFile' + index"
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                    <div class="collapse multi-collapse" :id="'multiCollapseFile' + index">
                      <div v-for="(acd, index) in project.ACD">
                        <small>
                          <a :href="acd" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >ACD {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                      <div v-for="(Facture, index) in project.Facture">
                        <small>
                          <a :href="Facture" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >Facture {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                      <div v-for="(Contrat, index) in project.Contrat">
                        <small>
                          <a :href="Contrat" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >Contrat {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                      <div v-for="(Plan, index) in project.Plan">
                        <small>
                          <a :href="Plan" target="_blank"
                            ><span class="text-body-4 blue--text"
                              >Plan {{ index + 1 }}</span
                            ></a
                          >
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ project.dateDevis.split("-").reverse().join("/") }}</td>
                <td>
                  <div class="d-flex flex-column align-items-center btn-group">
                    <v-btn
                      style="position: sticky"
                      text
                      color="blue darken-4"
                      data-toggle="collapse"
                      :data-target="'#multiCollapseVente' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseVente' + index"
                    >
                      <span>{{
                        project.Compteur.length == 1
                          ? "1 site"
                          : `${project.Compteur.length} sites`
                      }}</span>
                      <v-icon class="ml-2" size="17"> mdi-plus </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr class="collapse multi-collapse" :id="'multiCollapseVente' + index">
                <td colspan="6" style="padding: 3px; background-color: #dee2e6">
                  <table width="100%" height="100%">
                    <thead>
                      <tr>
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            ';width: 13vw;max-height: 15px !important;'
                          "
                        >
                          <v-select
                            dense
                            hide-details
                            v-if="project.selected.length > 0"
                            v-model="project.action"
                            menu-props="auto"
                            @change="changeSelect(project)"
                            :items="itemsStatus"
                            auto
                            item-text="state"
                            item-value="abbr"
                            :style="{
                              margin: '7px',
                              'max-height': '15px',
                              'min-height': '10px',
                            }"
                          ></v-select>
                        </th>
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            ';width: 13vw;max-height: 15px !important;'
                          "
                        >
                          N° Compteur
                        </th>
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            ';width: 13vw;max-height: 15px !important;'
                          "
                        >
                          Type de compteur
                        </th>
  
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            ';width: 13vw;max-height: 15px !important;'
                          "
                        >
                          Segment
                        </th>
  
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            ';width: 13vw;max-height: 15px !important;'
                          "
                        >
                          Début Devis
                        </th>
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            ';width: 13vw;max-height: 15px !important;'
                          "
                        >
                          Commentaire de refus
                        </th>
                        <th
                          :style="
                            'background: ' +
                            $styles.background +
                            '; width: 13vw;max-height: 15px !important;'
                          "
                        >
                          Statut
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, ind) in project.Compteur">
                        <td id="subtd-0">
                          <div class="d-flex justify-content-center align-items-center">
                            <v-checkbox
                              class="ma-0"
                              v-model="project.selected"
                              :value="v.id"
                              hide-details
                            ></v-checkbox>
                          </div>
                        </td>
                        <td id="subtd-1">
                          <div class="td-table">
                            <router-link :to="`/detail-compteur-Solar/${v.id}`">
                              <small>
                                {{ v.numCompteur }}
                              </small>
                            </router-link>
                          </div>
                        </td>
                        <td id="subtd-1">
                          <div class="td-table">
                            <small>
                              {{ v.typeDeProduction }}
                            </small>
                          </div>
                        </td>
                        <td id="subtd-1">
                          <div class="td-table">
                            <small>
                             <v-select v-if = "['C5', 'C4'].includes(v.segment) && v.status === '1' && v.typeDeProduction !== 'consommation'"
                                dense
                                hide-details
                                v-model="v.segment"
                                menu-props="auto"
                                @change="changeSegment(v)"
                                :items="segmentDict[v.segment]"
  
                                :style="{
                                  margin: '7px',
                                  'max-height': '15px',
                                  'min-height': '10px',
                                }" />
                                <span v-else> {{ v.segment }} </span>
                              
                            </small>
                          </div>
                        </td>
                        <td class="px-2" id="subtd-2">
                          <div class="td-table">
                            <span>{{ project.dateDevis }}</span>
                          </div>
                        </td>
                        <td id="subtd-4">
                          <div class="td-table">
                            <div
                              v-if="v.status == '2'"
                              class="d-flex justify-content-center"
                            >
                              <v-btn icon color="error" @click="openDialogComment(v)">
                                <v-icon>mdi-comment-eye</v-icon>
                              </v-btn>
                            </div>
                            <div class="d-flex justify-content-center" v-else>
                              <p>----</p>
                            </div>
                          </div>
                        </td>
                        <td id="subtd-6">
                          <div class="d-flex flex-column align-items-center btn-group">
                            <div class="d-flex flex-row justify-content-center">
                              <small class="small-status" v-if="v.status == '0'"
                                ><v-icon dense style="color: rgb(46, 116, 245)"
                                  >mdi-clock-outline</v-icon
                                ><b class="ml-2">en attente</b></small
                              >
                              <small class="small-status" v-if="v.status == '1'"
                                ><v-icon dense style="color: #00e676"
                                  >mdi-check-circle</v-icon
                                ><b class="ml-2">acceptée</b></small
                              >
                              <small class="small-status" v-if="v.status == '2'"
                                ><v-icon dense style="color: #ff0000"
                                  >mdi-close-circle</v-icon
                                ><b class="ml-2">refusée</b></small
                              >
                              <small class="small-status" v-if="v.status == '3'"
                                ><v-icon dense style="color: #ff0000">mdi mdi-alert</v-icon
                                ><b class="ml-2">erreur technique</b></small
                              >
                              <small class="small-status" v-if="v.status == '4'"
                                ><v-icon dense style="color: orange"
                                  >mdi-progress-clock</v-icon
                                ><b class="ml-2">en traitement</b></small
                              >
                              <!-- new buttons -->
                              <small class="small-status" v-if="v.status == '5'"
                                ><v-icon dense style="color: orange">mdi mdi-alert</v-icon
                                ><b class="ml-2">PDL résilié</b></small
                              >
                              <small class="small-status" v-if="v.status == '6'"
                                ><v-icon dense style="color: rgb(182, 58, 8)"
                                  >mdi-cancel</v-icon
                                ><b class="ml-2">annuler</b></small
                              >
                              <small class="small-status" v-if="v.status == '7'"
                                ><v-icon dense style="color: rgb(109, 106, 109)"
                                  >mdi mdi-lock-clock</v-icon
                                ><b class="ml-2">acd primer</b></small
                              >
                              <small class="small-status" v-if="v.status == '8'"
                                ><v-icon dense style="color: rgb(45, 120, 36)"
                                  >mdi-check-all</v-icon
                                ><b class="ml-2">compteur n'est pas téléopérable!</b></small
                              >
                              <small class="small-status" v-if="v.status == '9'"
                                ><v-icon dense style="color: rgb(45, 120, 36)"
                                  >mdi-check-all</v-icon
                                ><b class="ml-2"
                                  >Aucune mesure trouvée sur ce point.</b
                                ></small
                              >
                              <small class="small-status" v-if="v.status == '10'"
                                ><v-icon dense style="color: rgb(45, 120, 36)"
                                  >mdi-check-all</v-icon
                                ><b class="ml-2">PDL inexistant ou invalide.</b></small
                              >
                              <small class="small-status" v-if="v.status == '11'"
                                ><v-icon dense style="color: rgb(45, 120, 36)"
                                  >mdi-check-all</v-icon
                                ><b class="ml-2">Courbe de charge incomplète.</b></small
                              >
  
                              <div class="d-flex flex-row" v-if="showActionsBtns(v.status)">
                                <v-icon
                                  class="dropdown-toggle"
                                  data-toggle="collapse"
                                  :data-target="'#collapseStatut' + v.id"
                                  aria-expanded="false"
                                  :aria-controls="'collapseStatut' + index"
                                  size="20"
                                  left
                                >
                                  mdi-menu-down
                                </v-icon>
                              </div>
                            </div>
                            <div
                              class="collapse"
                              :id="'collapseStatut' + v.id"
                              style="border: 0px"
                              v-if="showActionsBtns(v.status)"
                            >
                              <div class="d-flex flex-row mt-4 mt-lg-2">
                                <!-- refuser -->
                                <v-btn
                                  title="refuser la demande de cotation"
                                  @click="ouvrireDialogeRefuserVente(v, 'single')"
                                  class="dropdown-project"
                                  v-if="
                                    $store.getters.hasPermissionByStatus(
                                      'DCS',
                                      v.status,
                                      'refusee'
                                    )
                                  "
                                  icon
                                  small
                                  plain
                                >
                                  <v-icon color="red"> mdi-close-circle </v-icon>
                                </v-btn>
                                <!-- accepter -->
                                <v-btn
                                  title="accepter la demande de cotation"
                                  @click="ouvrireDialogeAccepterVente(v, 'single')"
                                  v-if="
                                    $store.getters.hasPermissionByStatus(
                                      'DCS',
                                      v.status,
                                      'valider'
                                    )
                                  "
                                  class="dropdown-project"
                                  icon
                                  small
                                  plain
                                >
                                  <v-icon color="green accent-3"> mdi-check-circle </v-icon>
                                </v-btn>
                                <!-- annuler -->
                                <v-btn
                                  title="annuler demande de cotation"
                                  @click="
                                    ActionsBtns(
                                      v.id,
                                      '6',
                                      `Vous êtes sur le point d'annuler cette demande de cotation!`
                                    )
                                  "
                                  class="dropdown-project"
                                  v-if="
                                    $store.getters.hasPermissionByStatus(
                                      'DCS',
                                      v.status,
                                      'annuler'
                                    )
                                  "
                                  icon
                                  small
                                  plain
                                >
                                  <v-icon color="red accent-5"> mdi-cancel </v-icon>
                                </v-btn>
                                <!-- ac primer -->
                                <v-btn
                                  title="acd_primer"
                                  @click="
                                    ActionsBtns(
                                      v.id,
                                      '7',
                                      `Vous êtes sur le point de mettre cette demande de cotation en status acd pirimé!`
                                    )
                                  "
                                  class="dropdown-project"
                                  v-if="
                                    $store.getters.hasPermissionByStatus(
                                      'DCS',
                                      v.status,
                                      'acd_primer'
                                    )
                                  "
                                  icon
                                  small
                                  plain
                                >
                                  <v-icon color="grey accent-5">
                                    mdi mdi-lock-clock
                                  </v-icon>
                                </v-btn>
                                <!-- estimation -->
                                <v-btn
                                  title="estimation"
                                  @click="openModalEstimation(v, project.id)"
                                  class="dropdown-project"
                                  v-if="
                                    $store.getters.hasPermissionByStatus(
                                      'DCS',
                                      v.status,
                                      'estimation'
                                    )
                                  "
                                  icon
                                  small
                                  plain
                                >
                                  <v-icon color="purple"> mdi mdi-creation </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <noDataTable
              class="ma-4"
              v-if="projects.length == 0 && loadingTable == false"
            />
            <progressCircular class="ma-8" v-if="loadingTable == true" />
          </template>
        </datatable>
      </div>
      <div
        class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
      >
        <pagination
          v-if="projects.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/dataTable/no-data-table.vue";
import progressCircular from "../../components/dataTable/progress-circular.vue";
import axios from "@/plugins/axios";
import breadcrumb from "@/components/breadcrumb.vue";
import loadingComponent from "@/components/Loading";
import { Chart, registerables } from 'chart.js';
import dragDataPlugin from 'chartjs-plugin-dragdata';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(...registerables, dragDataPlugin, zoomPlugin);
export default {
  name: "validationDeCotation",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
    loadingComponent,
    breadcrumb,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "14vw", label: "Date d'ajout", name: "date", sortable: true },
      { width: "14vw", label: "Commercial", name: "Commerciale", sortable: true },
      { width: "14vw", label: "Raison Sociale", name: "Raison", sortable: true },
      { width: "14vw", label: "Pièce jointe", name: "jointe", sortable: true },
      { width: "14vw", label: "Début du devis", name: "dateDebut", sortable: true },
      { width: "14vw", label: "Statut", name: "status", sortable: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      commentRefus: "",
      typeDemandeAction: "",
      DemandeDeCotation: "",
      ouvrireDialogeRefuser: false,
      ouvrireDialogeAccepter: false,
      segmentDict:{
        "C5":["C5","C4","C3"],
        "C4":["C4","C3"],
      },
      puissance : "",
      segment: "",
      segmentChange: false,
      optionTarifaire: "",
      optionTarifaireChoices:{
        "C3":['HTALU5','HTACU5',"HTACUPM5","HTALUPM5"],
        "C4":['BTSUPCU4','BTSUPLU4']
      },
      CompteurSolarId: "",
      itemsStatus: [
        { state: "Actions", abbr: "" },
        { state: "Accepter", abbr: "1" },
        { state: "Refuser", abbr: "2" },
      ],
      dialogComment: false,
      ObjectComment: null,
      dialogLoading: false,
      loading: false,
      error: false,
      openDialogConsommation: false,
      mesuresBindigForEstimation: {},
      loadingTable: false,
      listNavs: [
        {
          name: "Cotation",
          link: "#",
        },
        {
          name: "Validations de cotations",
          link: "#",
        },
      ],
      filterStatus: "tout",
      projects: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "20", "30"],
      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      required(fieldName) {
        return (value) =>
          ("" + value).length > 0 ||
          (("" + value).length > 0 && value >= 0) ||
          `Veuillez saisir ${fieldName}`;
      },
      rules: {
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",
        pourcentageMaxMin: (v) =>
          (v <= 100 && v >= 0) || "La pourcentage doit être comprise entre 0 et 100",
        required(finPhrase) {
          return (value) => value != "" || value >= 0 || `Veuillez saisir ${finPhrase}`;
        },
        requiredC3Kva: (v) => (v != "" && v > 250) || "Veuillez saisir la puissance supérieure à 250",
        requiredC4Kva: (v) => (v != "" && v >= 36 && v < 250) || "Veuillez saisir la puissance entre 36 et 250",
      },
      chartLine: null,
      dataChart: new Array(12).fill(100 / 12) ,
      totalPercentage: 0,
      dataSets: [],
      lesMusereusTrier: {},
    };
  },
  async created() {
    this.loadingTable = true;
    await this.getData();
    this.loadingTable = false;
  },
   watch: {
    openDialogConsommation(val) {
      if (val) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
  },
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    user() {
      return this.$store.getters.getUser;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus("DC", status, "valider") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "refusee") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "annuler") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "acd_primer") ||
          this.$store.getters.hasPermissionByStatus("DC", status, "estimation")
        );
      };
    },
  },
  methods: {
    closeDialogConsommation(){
      this.openDialogConsommation = false
      this.dataSets = []
      this.totalPercentage = 0
    },
    getMonthsBetween(startDate1, endDate1) {
      // Ensure startDate and endDate are Date objects
      let startDate = new Date(startDate1);
      let endDate = new Date(endDate1);

      // Adjust if startDate is after endDate
      if (startDate > endDate) {
          let temp = startDate;
          startDate = endDate;
          endDate = temp;
      }

      let current = new Date(startDate);
      let months = [];

      // While current date is less than end date
      while (current <= endDate) {
          // Get the month and year of the current date
          let month = current.getMonth() + 1;  // Adjust for zero-based index
          let year = current.getFullYear();

          // Format as "Month-Year" if you want to also keep track of the year, or just push month for month numbers
          months.push(month); // Push the month number

          // Move to the next month
          current.setMonth(current.getMonth() + 1);
      }

      return months;
    },
    createChart() {
      if (this.chartLine) this.chartLine.destroy();
      const ctx = this.$refs.myChart.getContext('2d');
      this.chartLine = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          datasets: this.dataSets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false ,
          scales: {
            y: {
              min: 0,
              max: this.totalPercentage
            },
            x: {
              type: 'linear' // Ensure the x-axis type is linear for zooming to work properly
            }
          },
          plugins: {
            legend: {
              display: false
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'xy'
              },
              pan: {
                enabled: true,
                mode: 'x'
              }
            },
            dragData: {
              showTooltip: true,
              onDragStart: function (e) {
                return e;
              },
              onDrag: function (e, datasetIndex, index, value) {
                /*let blockedMonths = this.getMonthsBetween(this.mesuresBindigForEstimation.mesures.beginCDC, this.mesuresBindigForEstimation.mesures.endCDC);
                if (this.mesuresBindigForEstimation.CDC && blockedMonths.includes(index+1)) {
                  // Prevent dragging for blocked months
                  //this.dataSets[datasetIndex].data[index] = this.chartLine.data.datasets[datasetIndex].data[index];
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }*/
                this.dataSets[datasetIndex].data[index] = value;
              }.bind(this),
              onDragEnd: function (e, datasetIndex, index, value) {
                /* let blockedMonths = this.getMonthsBetween(this.mesuresBindigForEstimation.mesures.beginCDC, this.mesuresBindigForEstimation.mesures.endCDC);
                if (this.mesuresBindigForEstimation.CDC && blockedMonths.includes(index+1)) {
                  // Prevent update if month is blocked
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }*/
                this.redistributePercentage(datasetIndex, index, value);
                this.updateChart();
              }.bind(this)
            }
          },
          elements: {
            point: {
              radius: 5
            }
          },
          onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'grab' : 'default';
          }
        }
      });
    },
    updateChart() {
      this.chartLine.data.datasets = this.dataSets;
      //this.chartLine.data.datasets[0].data = this.dataChart;
      this.chartLine.update();
    },
    redistributePercentage(datasetIndex, draggedIndex, newValue) {
      this.lesMusereusTrier[this.dataSets[datasetIndex]['label']][draggedIndex] = newValue
      this.dataSets[datasetIndex][draggedIndex] = newValue; // Finally, set the dragged point to the new value
    },
    ouvrireDialogeAccepterVente(item, typeDemandeAction) {
      this.typeDemandeAction = typeDemandeAction;
      this.DemandeDeCotation = item;
      this.ouvrireDialogeAccepter = true;
    },
    ouvrireDialogeRefuserVente(item, typeDemandeAction) {
      this.typeDemandeAction = typeDemandeAction;
      this.DemandeDeCotation = item;
      this.ouvrireDialogeRefuser = true;
    },
    openDialogComment(item) {
      (this.dialogComment = true), (this.ObjectComment = item);
    },
    changeSelect(project) {
      if (project.action == "2") {
        this.typeDemandeAction = "many";
        this.DemandeDeCotation = project;
        this.ouvrireDialogeRefuser = true;
      } else if (project.action == "1") {
        this.typeDemandeAction = "many";
        this.DemandeDeCotation = project;
        this.ouvrireDialogeAccepter = true;
      }
    },
    changeSegment(item) {
      this.segmentChange = true;
      this.CompteurSolarId = item.id;
      this.segment = item.segment;
      //this.DemandeDeCotation = item;
    },
    cancelChangeSegment() {
      this.segmentChange = false;
      this.segment = "";
      this.CompteurSolarId = "";
      this.puissance = "";
      this.optionTarifaire = "";
      this.getData();
    },
    async sendChangeSegment() {
      
      console.log("this.CompteurSolarId",this.$refs.formSend.validate());
      if(this.$refs.formSend.validate() == false){
        this.$toast.error("modifier les champs obligatoires");
        return;
        }else{
            this.dialogLoading = true;
            this.loading = true
            console.log("this.CompteurSolarId");
            var formData = new FormData();
            formData.append("token", this.$cookies.get("token"));
            formData.append("compteurSolarId", this.CompteurSolarId);
            formData.append("puissance", this.puissance);
            formData.append("optionTarifaire", this.optionTarifaire);
            formData.append("segment", this.segment);
              await axios({
                  url: process.env.VUE_APP_URL_API_CLIENT + "changeSegmentSolarCollectif/",
                  method: "POST",
                  data: formData,
                })
                  .then((response) => {
                    this.segmentChange = false;
                    this.getData();
                    this.puissance = "";
                    this.optionTarifaire = "";
                    this.segment = "";
                    this.CompteurSolarId = "";

                  })
                  .catch((errors) => {
                    console.log(errors);
                  });
                  this.dialogLoading = false;
                  this.loading = false;
                }
     
       
    },
    async sendActionStatus(action, typeDemandeAction, DemandeDeCotation) {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      if (action == "2") {
        if (typeDemandeAction == "many") {
          formData.append("action", "2");
          formData.append("commentaire", this.commentRefus);
          formData.append("ids", DemandeDeCotation.selected);
        } else {
          formData.append("action", "2");
          formData.append("commentaire", this.commentRefus);
          formData.append("ids", DemandeDeCotation.id);
        }
      } else if (action == "1") {
        if (typeDemandeAction == "many") {
          formData.append("action", "1");
          formData.append("commentaire", "");
          formData.append("ids", DemandeDeCotation.selected);
        } else {
          formData.append("action", "1");
          formData.append("commentaire", "");
          formData.append("ids", DemandeDeCotation.id);
        }
      }
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeStatusCompteurSolar/",
        method: "POST",
        data: formData,
      })
        .then((response) => {})
        .catch((errors) => {
          console.log(errors);
        });
      this.typeDemandeAction = "";
      this.ouvrireDialogeAccepter = false;
      this.ouvrireDialogeRefuser = false;
    },
    CreerUnDevis(raisonSocial) {
      this.$router.push({
        path: `/Devis/effectuerUnDevis`,
        query: { raisonSocial: raisonSocial },
      });
    },
    CreerUneVisteTechnique(idDC) {
      const props = {
        idDC: idDC,
      };
      this.$router.push({ path: "/visite-technique/nouveau-formulaire", query: props });
    },
    CreerUneVisteInstallation(idDC) {
      const props = {
        idDC: idDC,
      };
      this.$router.push({
        path: "/visite-installation/ajoutVisiteInstallation",
        query: props,
      });
    },
    filterByStatus() {
      var pageNumber = 1;
      if (this.filterStatus != "tout") {
        this.tableData.statusFilter = this.filterStatus;
        this.getData(1);
      } else {
        this.tableData.statusFilter = "";
        this.getData(1);
      }
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT + "getAllDemandeDeCotationSolarCollectif/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getData(pageNumber = 1) {
      this.tableData.draw++;
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT + "getAllDemandeDeCotationSolarCollectif/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            console.log(data);
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getRulesForSegment() {
        if (this.segment === 'C4') {
          return this.rules.requiredC4Kva;
        } else if (this.segment === 'C3') {
          return this.rules.requiredC3Kva;
        } else {
          return [];
        }
    },
    
    sortData(d) {
      let alignedMeasures = [];
      let lesMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      let sortedDates = [];
      let sortedMesures = [];
      try{
        // Create an array of indices from 0 to d.date.length - 1
        const indices = [...d.date.keys()];

        // Sort the indices based on the comparison of dates
        indices.sort((a, b) => d.date[a] - d.date[b]);

        // Create new arrays for sorted dates and mesures using the sorted indices
        sortedDates = indices.map(index => d.date[index]);
        sortedMesures = indices.map(index => d.mesures[index]);

      }catch(err){

      }finally{
        // Return the sorted data, preserving the original sum
        lesMonths.forEach(month => {
          // Find the index of the month in sortedDates
          const index = sortedDates.indexOf(month);

          // If the month is found, use the corresponding measure, otherwise use 0
          if (index !== -1) {
            alignedMeasures.push(sortedMesures[index]);
          } else {
            alignedMeasures.push(0);
          }
        });
      }
      return {
          date: lesMonths,
          mesures: alignedMeasures,
          somme: d.somme
      };
    },
    getRandomRgbColor(key) {
        var colors = {
          "HPH": `rgb(0, 204, 255)`,
          "HCH": `rgb(51, 102, 255)`,
          "HPE": `rgb(204, 255, 51)`,
          "HCE": `rgb(255, 204, 102)`,
          "Pointe": `rgb(204, 102, 255)`,
          "HP": `rgb(0, 204, 255)`,
          "HC": `rgb(51, 102, 255)`,
          "BASE": `rgb(0, 204, 255)`,
        }
        return colors[key];
    },
    async defineDataSets(){
      this.totalPercentage = 0
      this.dataSets = []
      for (const key in this.lesMusereusTrier) {
        let category = key
        var item = {
          label: category,
          data: this.lesMusereusTrier[key],
          fill: false,
          borderColor: this.getRandomRgbColor(category),
          tension: 0.1
        }
        const sum = this.lesMusereusTrier[key].reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        this.totalPercentage += sum
        this.dataSets.push(item)
      }
    },
    async openModalEstimation(Compteur, idDC) {
      this.lesMusereusTrier = {}
      this.openDialogConsommation = true;
      var data = {};
      data["id"] = idDC;
      data["compteurId"] = Compteur["id"];
      data["numCompteur"] = Compteur["numCompteur"];
      data["parametreCompteur"] = Compteur["parametreCompteur"];
      data["donnerCourbeDeCharge"] = Compteur["donnerCourbeDeCharge"];
      data["CDC"] = false;
      data["mesures"] = {};
      if (data["donnerCourbeDeCharge"] != null) {
        data["CDC"] = true;
        try {
          data["mesures"]["beginCDC"] = data["donnerCourbeDeCharge"]["fromDebut"];
          data["mesures"]["endCDC"] = data["donnerCourbeDeCharge"]["toEnd"];
        } catch (er) {
          data["mesures"]["beginCDC"] = null;
          data["mesures"]["endCDC"] = null;
        }
        if (data["parametreCompteur"] == "C5HP") {
          data["mesures"]["nbrMonth"] = data["donnerCourbeDeCharge"]["days_difference"];
          data["mesures"]["HP"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hp"]) / 1000) * 100) /
            100;
          data["mesures"]["HC"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hc"]) / 1000) * 100) /
            100;
        } else if (data["parametreCompteur"] == "C5BASE") {
          data["mesures"]["nbrMonth"] = data["donnerCourbeDeCharge"]["days_difference"];
          data["mesures"]["BASE"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["base"]) / 1000) * 100) /
            100;
        } else if (data["parametreCompteur"] == "C4") {
          data["mesures"]["nbrMonth"] = data["donnerCourbeDeCharge"]["days_difference"];
          data["mesures"]["HPH"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hph"]) / 1000) * 100) /
            100;
          data["mesures"]["HCH"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hch"]) / 1000) * 100) /
            100;
          data["mesures"]["HPE"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hpe"]) / 1000) * 100) /
            100;
          data["mesures"]["HCE"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hce"]) / 1000) * 100) /
            100;
        } else if (
          data["parametreCompteur"] == "C3" ||
          data["parametreCompteur"] == "C2" ||
          data["parametreCompteur"] == "C1"
        ) {
          data["mesures"]["nbrMonth"] = data["donnerCourbeDeCharge"]["days_difference"];
          data["mesures"]["HPH"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hph"]) / 1000) * 100) /
            100;
          data["mesures"]["HCH"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hch"]) / 1000) * 100) /
            100;
          data["mesures"]["HPE"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hpe"]) / 1000) * 100) /
            100;
          data["mesures"]["HCE"] =
            Math.round((parseFloat(data["donnerCourbeDeCharge"]["hce"]) / 1000) * 100) /
            100;
          data["mesures"]["Pointe"] =
            Math.round(
              (parseFloat(data["donnerCourbeDeCharge"]["pointe"]) / 1000) * 100
            ) / 100;
        }
      } else if (Compteur["donnerMesure"] != null) {
        data["mesures"]["beginCDC"] = null;
        data["mesures"]["endCDC"] = null;
        if (data["parametreCompteur"] == "C5HP") {
          data["mesures"]["nbrMonth"] = Compteur["donnerMesure"]["HP"]["date"].length;
          data["mesures"]["HP"] = Compteur["donnerMesure"]["HP"]["somme"];
          data["mesures"]["HC"] = Compteur["donnerMesure"]["HC"]["somme"];
        } else if (data["parametreCompteur"] == "C5BASE") {
          data["mesures"]["nbrMonth"] = Compteur["donnerMesure"]["BASE"]["date"].length;
          data["mesures"]["BASE"] = Compteur["donnerMesure"]["BASE"]["somme"];
        } else if (data["parametreCompteur"] == "C4") {
          data["mesures"]["nbrMonth"] = Compteur["donnerMesure"]["HPH"]["date"].length;
          data["mesures"]["HPH"] = Compteur["donnerMesure"]["HPH"]["somme"];
          data["mesures"]["HCH"] = Compteur["donnerMesure"]["HCH"]["somme"];
          data["mesures"]["HPE"] = Compteur["donnerMesure"]["HPE"]["somme"];
          data["mesures"]["HCE"] = Compteur["donnerMesure"]["HCE"]["somme"];
        } else if (
          data["parametreCompteur"] == "C3" ||
          data["parametreCompteur"] == "C2" ||
          data["parametreCompteur"] == "C1"
        ) {
          data["mesures"]["nbrMonth"] = Compteur["donnerMesure"]["HPH"]["date"].length;
          data["mesures"]["HPH"] = Compteur["donnerMesure"]["HPH"]["somme"];
          data["mesures"]["HCH"] = Compteur["donnerMesure"]["HCH"]["somme"];
          data["mesures"]["HPE"] = Compteur["donnerMesure"]["HPE"]["somme"];
          data["mesures"]["HCE"] = Compteur["donnerMesure"]["HCE"]["somme"];
          data["mesures"]["Pointe"] = Compteur["donnerMesure"]["Pointe"]["somme"];
        }
      } else {
        data["mesures"]["beginCDC"] = null;
        data["mesures"]["endCDC"] = null;
        data["mesures"]["nbrMonth"] = 0;
        if (data["parametreCompteur"] == "C5HP") {
          data["mesures"]["HP"] = 0;
          data["mesures"]["HC"] = 0;
        } else if (data["parametreCompteur"] == "C5BASE") {
          data["mesures"]["BASE"] = 0;
        } else if (data["parametreCompteur"] == "C4") {
          data["mesures"]["HPH"] = 0;
          data["mesures"]["HCH"] = 0;
          data["mesures"]["HPE"] = 0;
          data["mesures"]["HCE"] = 0;
        } else if (
          data["parametreCompteur"] == "C3" ||
          data["parametreCompteur"] == "C2" ||
          data["parametreCompteur"] == "C1"
        ) {
          data["mesures"]["HPH"] = 0;
          data["mesures"]["HCH"] = 0;
          data["mesures"]["HPE"] = 0;
          data["mesures"]["HCE"] = 0;
          data["mesures"]["Pointe"] = 0;
        }
      }
      /*this.totalPercentage*/
      if (Compteur["donnerMesure"] != null) {
        if (data["parametreCompteur"] == "C5HP") {
          
          for (const key in Compteur["donnerMesure"]) {
            // key will be "HC" and then "HP"
            var res = this.sortData(Compteur["donnerMesure"][key])
            this.lesMusereusTrier[key] = res['mesures']
            
          }
          data["mesures"]["lesMoisTurpe"] = Compteur["donnerMesure"]["HP"]["date"];
          data["mesures"]["nbrMonthTURPE"] =
            Compteur["donnerMesure"]["HP"]["date"].length;
          data["mesures"]["HPTURPE"] = Compteur["donnerMesure"]["HP"]["somme"];
          data["mesures"]["HCTURPE"] = Compteur["donnerMesure"]["HC"]["somme"];
        } else if (data["parametreCompteur"] == "C5BASE") {
          for (const key in Compteur["donnerMesure"]) {
            // key will be "HC" and then "HP"
            var res = this.sortData(Compteur["donnerMesure"][key])
            this.lesMusereusTrier[key] = res['mesures']
          }
          data["mesures"]["lesMoisTurpe"] = Compteur["donnerMesure"]["BASE"]["date"];
          data["mesures"]["nbrMonthTURPE"] =
            Compteur["donnerMesure"]["BASE"]["date"].length;
          data["mesures"]["BASETURPE"] = Compteur["donnerMesure"]["BASE"]["somme"];
        } else if (data["parametreCompteur"] == "C4") {
          for (const key in Compteur["donnerMesure"]) {
            var res = this.sortData(Compteur["donnerMesure"][key])
            this.lesMusereusTrier[key] = res['mesures']
          }
          data["mesures"]["lesMoisTurpe"] = Compteur["donnerMesure"]["HPH"]["date"];
          data["mesures"]["nbrMonthTURPE"] =
            Compteur["donnerMesure"]["HPH"]["date"].length;
          data["mesures"]["HPHTURPE"] = Compteur["donnerMesure"]["HPH"]["somme"];
          data["mesures"]["HCHTURPE"] = Compteur["donnerMesure"]["HCH"]["somme"];
          data["mesures"]["HPETURPE"] = Compteur["donnerMesure"]["HPE"]["somme"];
          data["mesures"]["HCETURPE"] = Compteur["donnerMesure"]["HCE"]["somme"];
        } else if (
          data["parametreCompteur"] == "C3" ||
          data["parametreCompteur"] == "C2" ||
          data["parametreCompteur"] == "C1"
        ) {
          for (const key in Compteur["donnerMesure"]) {
            var res = this.sortData(Compteur["donnerMesure"][key])
            this.lesMusereusTrier[key] = res['mesures']
          }
          data["mesures"]["lesMoisTurpe"] = Compteur["donnerMesure"]["HPH"]["date"];
          data["mesures"]["nbrMonthTURPE"] =
            Compteur["donnerMesure"]["HPH"]["date"].length;
          data["mesures"]["HPHTURPE"] = Compteur["donnerMesure"]["HPH"]["somme"];
          data["mesures"]["HCHTURPE"] = Compteur["donnerMesure"]["HCH"]["somme"];
          data["mesures"]["HPETURPE"] = Compteur["donnerMesure"]["HPE"]["somme"];
          data["mesures"]["HCETURPE"] = Compteur["donnerMesure"]["HCE"]["somme"];
          data["mesures"]["PointeTURPE"] = Compteur["donnerMesure"]["Pointe"]["somme"];
        }
      } else {
        data["mesures"]["lesMoisTurpe"] = null;
        if (data["parametreCompteur"] == "C5HP") {
          
          var res = this.sortData(null)
          this.lesMusereusTrier["HP"] = res['mesures']
          this.lesMusereusTrier["HC"] = res['mesures']
          data["mesures"]["HPTURPE"] = 0;
          data["mesures"]["HCTURPE"] = 0;
        } else if (data["parametreCompteur"] == "C5BASE") {
          var res = this.sortData(null)
          this.lesMusereusTrier["BASE"] = res['mesures']
          data["mesures"]["BASETURPE"] = 0;
        } else if (data["parametreCompteur"] == "C4") {
          var res = this.sortData(null)
          this.lesMusereusTrier["HPH"] = res['mesures']
          this.lesMusereusTrier["HCH"] = res['mesures']
          this.lesMusereusTrier["HPE"] = res['mesures']
          this.lesMusereusTrier["HCE"] = res['mesures']
          data["mesures"]["HPHTURPE"] = 0;
          data["mesures"]["HCHTURPE"] = 0;
          data["mesures"]["HPETURPE"] = 0;
          data["mesures"]["HCETURPE"] = 0;
        } else if (
          data["parametreCompteur"] == "C3" ||
          data["parametreCompteur"] == "C2" ||
          data["parametreCompteur"] == "C1"
        ) {
          var res = this.sortData(null)
          this.lesMusereusTrier["HPH"] = res['mesures']
          this.lesMusereusTrier["HCH"] = res['mesures']
          this.lesMusereusTrier["HPE"] = res['mesures']
          this.lesMusereusTrier["HCE"] = res['mesures']
          this.lesMusereusTrier["Pointe"] = res['mesures']
          data["mesures"]["HPHTURPE"] = 0;
          data["mesures"]["HCHTURPE"] = 0;
          data["mesures"]["HPETURPE"] = 0;
          data["mesures"]["HCETURPE"] = 0;
          data["mesures"]["PointeTURPE"] = 0;
        }
      }
      await this.defineDataSets()
      data["ProfilCompteur"] = "Bureau";
      data["ProfilWeekend"] = "noWeek";
      this.mesuresBindigForEstimation = data;
      
    },
    async getEstimationCdcFromMesures() {
      this.$refs.formEstimation.validate();
      this.dialog = true;
      this.loading = true;
      this.error = false;
      if (this.$refs.formEstimation.validate()) {
        var formData = new FormData();
        formData.append("token", this.$store.getters.getUserToken);
        formData.append("lesDonner", JSON.stringify(this.mesuresBindigForEstimation));
        formData.append("dataSets", JSON.stringify(this.lesMusereusTrier));
        

        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT + "getEstimationCdcFromMesuresCollectif/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.status == 200) {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.getData(this.pagination.currentPage);
      this.openDialogConsommation = false;
      
      this.dataSets = []
      this.dialog = false;
      this.loading = false;
      this.error = false;
    },
    ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          var formData = new FormData();
          formData.append("ids", id);
          formData.append("action", status);
          formData.append("commentaire", "");
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeStatusCompteurSolar/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                this.getData(this.pagination.currentPage);
                console.log(res.data);
              }
              this.dialog = false;
              this.loading = false;
              this.error = false;
            })
            .catch((err) => {
              console.log(err);
              this.dialog = false;
              this.loading = false;
              this.error = false;
            });
        }
      });

      this.dialog = false;
      this.loading = false;
      this.error = false;
    },
    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style scoped>
#table-validation-cotation th {
  color: white;
  font-weight: bold;
}
@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  @import "../../components/dataTable/style.css";
  #table-validation-cotation td:nth-of-type(1):before {
    content: "Date d'ajout";
  }
  #table-validation-cotation td:nth-of-type(2):before {
    content: "Commercial";
  }
  #table-validation-cotation td:nth-of-type(3):before {
    content: "Raison Sociale";
  }
  #table-validation-cotation td:nth-of-type(4):before {
    content: "Energie";
  }
  #table-validation-cotation td:nth-of-type(5):before {
    content: "Compteur";
  }
  #table-validation-cotation td:nth-of-type(6):before {
    content: "Pièce jointe";
  }
  #table-validation-cotation td:nth-of-type(7):before {
    content: "Début de contrat";
  }
  #table-validation-cotation td:nth-of-type(8):before {
    content: "Statut";
  }
}
.small-status {
  width: max-content;
}
.td-siret {
  color: #5f98e4 !important;
  font-weight: bold;
}
canvas {
  width: 100% !important;
  height: 300px !important;
}
</style>
