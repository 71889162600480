<template>
  <div class="wrapper">
    <loadingComponent
      style="position: absolute"
      :dialogProps="loadingDialog"
      :messageDialogeProps="messageError"
      :loadingProps="loadingEnedis"
      :errorProps="errorDialog"
    />
    <div class="px-6 py-8">
      <!-- siret -->
      <v-form ref="formSiret">
        <div class="search-siret-wrapper">
          <v-text-field
          v-model="siret"
          placeholder="Siret ou Raison sociale"
          prepend-inner-icon="mdi-magnify"
          outlined
          class="search-siret-input"
          :rules="[
            $rules.required('un Siret/Raison Social valide'),
            rules.siretLength
          ]"
          ></v-text-field>
          <v-btn elevation="0" @click="getInfoSociete" class="search-siret-btn" :loading="loadingSiret">
            Rechercher
          </v-btn>
        </div>
      </v-form>

      <!-- info societe -->
      <div v-if="infoSociete" class="card-white card-info-societe">
        <div class="header-title">
          <p class="title"><span class="mdi mdi-domain"></span> Informations societe:</p>
        </div>
        <div class="content">
          <div class="info-item-container">
            <div class="info-item">
              <p class="info-item-label">Raison Sociale</p>
              <p class="info-item-value">{{ infoSociete.raison }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Siret</p>
              <p class="info-item-value">{{ infoSociete.siret }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Siren</p>
              <p class="info-item-value">{{ infoSociete.siren }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Code Naf</p>
              <p class="info-item-value">{{ infoSociete.codeNaf }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Code Insee</p>
              <p class="info-item-value">{{ infoSociete.codeInsee }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">RCS</p>
              <p class="info-item-value">{{ infoSociete.RCS }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">TVA</p>
              <p class="info-item-value">{{ infoSociete.TVA }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Code Postal</p>
              <p class="info-item-value">{{ infoSociete.codePostal }}</p>
            </div>
            <div class="info-item address">
              <p class="info-item-label">Addresse</p>
              <p class="info-item-value">{{ infoSociete.adressePostal }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- info compteur -->
      <div v-if="infoSociete" class="card-white card-info-societe">
        <div class="header-title">
          <p class="title"><span class="mdi mdi-counter"></span> Informations Compteur:</p>
        </div>

        <!-- compteur -->
        <v-form ref="formCompteur">
          <div class="search-siret-wrapper">
            <v-autocomplete
            v-model="selectedCompteur"
            :items="listCompteur"
            item-text="numCompteur"
            item-value="id"
            outlined
            label="Compteur"
            ></v-autocomplete>
            <v-btn elevation="0" @click="getInfosCompteur" class="search-siret-btn" :loading="loadingCompteur">
            Rechercher
          </v-btn>
          </div>
        </v-form>
        <div v-if="infoCompteur" class="content">
          <div class="info-item-container">
            <div class="info-item">
              <p class="info-item-label">Option Tarifaire</p>
              <p class="info-item-value">{{ infoCompteur.optionTarifaire }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Segment</p>
              <p class="info-item-value">{{ infoCompteur.segment }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Puissance</p>
              <p class="info-item-value">{{ infoCompteur.puissanceMaximale }}</p>
            </div>
            <div class="info-item">
              <p class="info-item-label">Puissance maximale atteinte (kva)</p>
              <p class="info-item-value">{{ infoCompteur.puissanceAtteinteMaximale }}</p>
            </div>
          </div>
        </div>
        <div>
          <CourbeDeCharge
            v-if="infoCompteur && listCompteur.length > 0"
            :id="selectedCompteur"
          />
        </div>
      </div>
      <!-- bornes content -->
      <div v-if="infoCompteur">
        <!-- list des bornes -->
        <div class="card-white card-borne-list">
          <div class="header">
            <p class="title">List des bornes</p>
          </div>
          <v-form ref="formBornes">
            <div class="w-100 p-4">
              <div class="imgs-wrapper">
                <div class="tgbt-img-wrapper">
                  <span class="label">TGBT</span>
                  <img :src="require(`@/assets/images/tgbt.png`)" class="tgbt-img" />
                </div>
                <div class="line">
                  <div class="input-tgbt-tdirve">
                    <v-row>
                      <v-col sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model="allimentation.distance_alimentation_interieur"
                          label="Distance interieur"
                          outlined
                          dense
                          hide-details
                          suffix="ML"
                          :rules="[rules.required, rules.isNumberOrFloat]"
                        />
                      </v-col>
                      <v-col sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model="allimentation.distance_alimentation_exterieur"
                          label="Distance exterieur"
                          outlined
                          dense
                          hide-details
                          suffix="ML"
                          :rules="[rules.required, rules.isNumberOrFloat]"
                        />
                      </v-col>
                      <v-col sm="12" md="3" lg="3" xl="3">
                        <v-select
                        v-model="allimentation.type_Tranche"
                        :items="listSOL"
                        label="SOL"
                        class="mb-4"
                        dense
                        outlined
                        hide-details
                        style="min-width: 80px;"
                        :rules="[rules.hasItemSelected]"
                        />
                      </v-col>
                      <v-col sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                        v-model="allimentation.distanceTranche"
                        label="Metre tranchee"
                        class="mb-4"
                        dense
                        outlined
                        hide-details
                        style="min-width: 80px;"
                        :rules="[rules.accepteZero, rules.isNumberOrFloat]"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="12" md="3" lg="3" xl="3">
                        <v-select
                        v-model="allimentation.protection_cable"
                        :items="['TPC','IRL']"
                        label="Protection de cable"
                        class="mb-4"
                        dense
                        outlined
                        hide-details
                        style="min-width: 80px;"
                        :rules="[rules.hasItemSelected]"
                        />
                      </v-col>


                     
                    </v-row>
                  </div>
                </div>

                <div class="tdirve-img-wrapper">
                  <span class="label">TDIRVE</span>
                  <img :src="require(`@/assets/images/tdirve.png`)" class="tdirve-img" />
                </div>
              </div>

              <div class="borne-wrapper">
                <div class="nbr-borne-wrapper">
                  <div>
                    <div class="d-flex justify-end">
                      <v-btn
                        @click="addBorne"
                        class="borne-btn-add"
                        title="Ajouter des bornes"
                        elevation="0"
                      >
                        <v-icon class="borne-btn-add-icon">mdi-plus</v-icon>
                      </v-btn>
                      <span class="borne-counter">{{ listDesBornes.length }}</span>
                    </div>
                  </div>
                </div>
                <div class="borne-list">
                  <div
                  style="position: relative"
                  class="borne pa-2"
                  v-for="(line, index) in listDesBornes"
                  :key=index
                  >
                    <v-icon
                    style="position: absolute; top: 0px; right: 0px"
                    size="32"
                    class="borne-btn-close"
                    @click="() => removeBorne(index)"
                    >mdi-close-box</v-icon>
                    <div class="d-flex flex-row flex-nowrap align-items-center justify-center gap-2 mb-4">
                      <p class="Poppins-Bold ma-0">Borne n°{{ index + 1 }}</p>
                    </div>
                    <div class="">
                      <!-- <v-text-field
                      v-model="line.nbr_borne"
                      label="Nom de Borne"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[$rules.required('un N° Born valide'),rules.isNumber,rules.greaterThanZero]"
                      /> -->
                      <!-- <v-select
                      v-model="line.type_point_recharge"
                      :items="computedListTypePointDeRecharge"
                      item-text="format"
                      item-value="format"
                      item-disabled="disabled"
                      label="Type point de recharge"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[$rules.required('un Type point recharge Born valide')]"
                      /> -->
                      <v-select
                      v-model="line.type_point_recharge"
                      :items="listTypePointDeRecharge"
                      item-text="format"
                      item-value="format"
                      item-disabled="disabled"
                      label="Type point de recharge"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[$rules.required('un Type point recharge Born valide')]"
                      />
                      <v-select
                      v-model="line.montage"
                      :items="listMontage"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[rules.hasItemSelected]"
                      />

                      <v-text-field
                      v-model="line.ml_interieur"
                      label="Distance interieur"
                      class="mb-4"
                      outlined
                      dense
                      hide-details="auto"
                      suffix="ML"
                      style="min-width: 80px;"
                      :rules="[rules.accepteZero, rules.isNumberOrFloat]"
                      />
                      <v-text-field
                      v-model="line.ml_exterieur"
                      label="Distance exterieur"
                      class="mb-4"
                      outlined
                      dense
                      hide-details="auto"
                      suffix="ML"
                      style="min-width: 80px;"
                      :rules="[rules.accepteZero, rules.isNumberOrFloat]"
                      />
                      <v-select
                      v-model="line.sol"
                      :items="listSOL"
                      label="SOL"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[rules.hasItemSelected]"
                      />
                      <v-text-field
                      v-model="line.metre_tranche"
                      label="Metre tranchee"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[rules.accepteZero, rules.isNumberOrFloat]"
                      ></v-text-field>
                      <v-select
                      v-model="line.protection_cable"
                      :items="['TPC','IRL']"
                      label="Protection de cable"
                      class="mb-4"
                      dense
                      outlined
                      hide-details
                      style="min-width: 80px;"
                      :rules="[rules.hasItemSelected]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <button class="valider-btn" @click="getListPrixParBorne" :disabled="listDesBornes.length==0">
                <div v-if="loadingListPrixParBorne" class="loader"></div>
                <span v-else>Valider</span>
              </button>
            </div>
          </v-form>
        </div>

        <!-- alimentation -->
        <div  class="card-white card-alimentation" v-if="listPrixParBorne.length>0 && resultatAllimentation">
          <div class="header">
            <p class="title"># Allimentation</p>
          </div>
          <div class="content-wrapper">
            <div class="item-card">
              <span class="mdi mdi-hammer-screwdriver card-icon"></span>
              <div class="item-content">
                <p class="title">MATERIEL</p>
                <p class="price">{{to_fixed2LocaleString(resultatAllimentation.Matrielle)}}</p>
              </div>
            </div>
            <div class="item-card">
              <span class="mdi mdi-car-shift-pattern card-icon"></span>
              <div class="item-content">
                <p class="title">VRD</p>
                <p class="price">{{to_fixed2LocaleString(resultatAllimentation.VRD)}}</p>
              </div>
            </div>
            <div class="item-card">
              <span class="mdi mdi-cash-multiple card-icon"></span>
              <div class="item-content">
                <p class="title">Total</p>
                <p class="price">{{to_fixed2LocaleString(resultatAllimentation.total)}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- list des prix par bornes -->
        <template v-for="(line,lineIndex) in listPrixParBorne" v-if="listPrixParBorne.length>0">
          <v-form :ref="'formListPrixParBorne'+line.id">
            <v-expansion-panels accordion flat>
              <v-expansion-panel
                class="card-white card-borne-list-by-price"
              >
                <v-expansion-panel-header class="header">
                    <p class="title"># Born de Recharge N°: {{ lineIndex+1 }}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="light-table-wrapper">
                    <table class="light-table">
                      <thead>
                        <tr>
                          <th v-for="headerItem in headerListPrixParBorne">{{ headerItem.label }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="line.data.length>0">
                        <tr v-for="item in line.data">
                          <td>
                            {{ item.marque }}
                          </td>
                          <td>
                            {{ item.type_point_recharge }}
                          </td>
                          <td>
                            {{ item.montage }}
                          </td>
                          <td>
                            {{ to_fixed2LocaleString(item.prix_borne) }}
                          </td>
                          <td>
                            {{ to_fixed2LocaleString(item.materiel) }}
                          </td>
                          <td>
                            {{ to_fixed2LocaleString(item.vrd) }}
                          </td>
                          <td>
                            {{ to_fixed2LocaleString(item.prod) }}
                          </td>
                          <td>
                            {{ to_fixed2LocaleString(item.total) }}
                          </td>
                        </tr>
                        <!-- questions -->
                      <tr>
                        <td colspan="8">
                          <div class="questions-wrapper">
                            <div class="question">
                              <p class="question-label">Choisir type d'offre :</p>
                              <v-select
                              v-model="line.offre"
                              :items="line.listMarque"
                              class="question-field"
                              dense
                              outlined
                              hide-details
                              :rules="[rules.hasItemSelected]"
                              >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Prime advenir :</p>
                              <v-text-field
                              v-model="line.prime_advenir"
                              dense
                              outlined
                              hide-details
                              class="question-field"
                              :rules="[rules.accepteZero, rules.isNumberOrFloat]"
                              />
                            </div>
                            <div class="question">
                              <p class="question-label">Paiement par carte bleu :</p>
                              <v-select
                                v-model="line.payment_type"
                                :items="['oui', 'non']"
                                class="question-field"
                                dense
                                outlined
                                hide-details
                                :rules="[rules.hasItemSelected]"
                                >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Charge Dynamique:</p>
                              <v-select
                                v-model="line.chargeDynamique"
                                :items="['oui', 'non']"
                                class="question-field"
                                dense
                                outlined
                                hide-details
                                :rules="[rules.hasItemSelected]"
                                >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Pack cartes RFID:</p>
                              <v-select
                                v-model="line.packCartesRFID"
                                :items="['oui', 'non']"
                                class="question-field"
                                dense
                                outlined
                                hide-details
                                :rules="[rules.hasItemSelected]"
                                >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Butée de parking :</p>
                              <v-select
                              v-model="line.butee_parking"
                              :items="['oui', 'non']"
                              class="question-field"
                              dense
                              outlined
                              hide-details
                              :rules="[rules.hasItemSelected]"
                              >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Logo Voiture :</p>
                              <v-select
                              v-model="line.logo_voiture"
                              :items="['oui', 'non']"
                              class="question-field"
                              dense
                              outlined
                              hide-details
                              :rules="[rules.hasItemSelected]"
                              >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Ligne Blanche :</p>
                              <v-select
                              v-model="line.ligne_blanche"
                              :items="['oui', 'non']"
                              class="question-field"
                              dense
                              outlined
                              hide-details
                              :rules="[rules.hasItemSelected]"
                              >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Panneau Vehicule Recharge :</p>
                              <v-select
                              v-model="line.panneau_vehicule_recharge"
                              :items="['oui', 'non']"
                              class="question-field"
                              dense
                              outlined
                              hide-details
                              :rules="[rules.hasItemSelected]"
                              >
                              </v-select>
                            </div>
                            <div class="question">
                              <p class="question-label">Arceaux de protection :</p>
                              <v-select
                              v-model="line.arceaux_protection"
                              :items="['oui', 'non']"
                              class="question-field"
                              dense
                              outlined
                              hide-details
                              :rules="[rules.hasItemSelected]"
                              >
                              </v-select>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="headerListDesBornes.length">
                            <p class="w-100 text-center">aucune borne ajouté!!!</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="footer">
                    <button class="valider-btn" @click="(e)=> getPrice(e,line)">
                      Valider
                    </button>
                  </div>
                  <div v-if="line.selectedPrice" class="signalitique-container">
                    <!-- TARIF SANS SIGNALETIQUE -->
                    <div class="table-signalitique-wrapper">
                      <table class="table-signalitique">
                        <thead>
                          <tr>
                            <th colspan="2">TARIF SANS SIGNALETIQUE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="value-title">Total HTA</td>
                          </tr>
                          <tr>
                            <td>{{ to_fixed2LocaleString(line.selectedPrice.prixSansSignalitiqueTTC) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- TARIF AVEC SIGNALETIQUE -->
                    <div class="table-signalitique-wrapper">
                      <table class="table-signalitique">
                        <thead>
                          <tr>
                            <th colspan="2">TARIF AVEC SIGNALETIQUE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="value-title">Total HTA</td>
                          </tr>
                          <tr>
                            <td>{{ to_fixed2LocaleString(line.selectedPrice.prixAvecSignalitiqueTTC) }}</td>
                          </tr>
                        </tbody>
                      </table>
    
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </template>
        
        <!-- TARIF (SANS SIGNALETIQUE/AVEC SIGNALETIQUE) -->
        <div class="card-white card-tarif-signalitique" v-if="listPrixParBorne.length>0">
          <div class="header">
            <p class="title">Calculer TARIF (SANS SIGNALETIQUE/AVEC SIGNALETIQUE)</p>
            <v-btn elevation="0" class="valider-btn" @click="getAllPrice()">
              Calculer
            </v-btn>
          </div>
          <div v-if="resultatFinal == true && listPrixParBorne.length>0" class="card-borne-list-by-price">
            <div class="signalitique-container">
              <!-- TARIF SANS SIGNALETIQUE -->
              <div class="table-signalitique-wrapper">
                <table class="table-signalitique">
                  <thead>
                    <tr>
                      <th colspan="2"></th>
                      <th colspan="2">TARIF SANS SIGNALETIQUE</th>
                      <th colspan="2">TARIF AVEC SIGNALETIQUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="2" class="value-title"></td>
                      <td colspan="2" class="value-title">Total HTA</td>
                      <td colspan="2" class="value-title">Total HTA</td>
                    </tr>
                    <tr  v-for="(line,lineIndex) in listPrixParBorne" :key="'res'+lineIndex" >
                      <td colspan="2">#Born N°{{lineIndex+1}}</td>
                      <td colspan="2">{{ to_fixed2LocaleString(line.selectedPrice.prixSansSignalitiqueTTC) }}</td>
                      <td colspan="2">{{ to_fixed2LocaleString(line.selectedPrice.prixAvecSignalitiqueTTC) }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">#Allimentation</td>
                      <td colspan="2">{{ to_fixed2LocaleString(resultatAllimentation.totalHTA) }}</td>
                      <td colspan="2">{{ to_fixed2LocaleString(resultatAllimentation.totalHTA) }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">#Total</td>
                      <td colspan="2">{{ to_fixed2LocaleString(totalResultat.totalSansSignaletique) }}</td>
                      <td colspan="2">{{ to_fixed2LocaleString(totalResultat.totalAvecSignaletique) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Generate Presentation -->
        <div class="result-footer" v-if="resultatFinal == true && listPrixParBorne.length>0">
          <!--<v-btn elevation="0" class="generate-btn" @click="generatePresentation()" :disabled="devisData.resultatSelected && !devisData.resultatSelected.length>0" :loading="loadingGeneratingPres">
            generer presentation
          </v-btn>-->
          <v-btn elevation="0" class="save-btn" @click="saveResult">Save resulat</v-btn>
          <devisPdfGenerator 
          :buttonText="'generer presentation'" 
          buttonBgColor="#607d8b" 
          buttonTextColor="#ffffff" 
          styleProps="height:48px" 
          :devisData="devisData" 
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import loadingComponent from "../../components/Loading";
import CourbeDeCharge from "./components/CourbeDeCharge.vue";
import devisPdfGenerator from "./components/devisPdfGenerator.vue";
import PDFDevisBorneIRVEGenerator from './components/PDFDevisBorneIRVEGenerator.js';

export default {
  name: "EffectuerUnDevis",
  components: { loadingComponent,CourbeDeCharge,devisPdfGenerator },
  data() {
    return {
      loadingDialog: false,
      errorDialog: false,
      messageError: "",
      loadingEnedis: false,
      loadingSiret: false,
      loadingCompteur: false,
      loadingListPrixParBorne: false,
      loadingGeneratingPres: false,
      siret: null,
      infoSociete: null,
      selectedCompteur: null,
      infoCompteur: null,
      listCompteur:[],
      listDesBornes: [],
      listPrixParBorne: [],
      totalResultat:{},
      headerListDesBornes: [
        { label:"Nombre de borne" },
        { label:"Tupe point de recharge" },
        { label:"Montage" },
        { label:"ML interieur" },
        { label:"ML exterieur" },
        { label:"SOL" },
        { label:"Metre tranchee" },
      ],
      headerListPrixParBorne: [
        { label:"Marque" },
        { label:"TYPE POINT DE RECHARGE" },
        { label:"MONTAGE" },
        { label:"PRIX BORNE" },
        { label:"MATERIEL" },
        { label:"VRD" },
        { label:"PROD" },
        { label:"TOTAL" },
      ],
      nouveauPDL: "non",
      messageWarning: "",
      warningMessageCount: 0,
      maxValue: 0,
      listMarque: [],
      listMontage: ["MURAL", "PIED"],
      listTypePointDeRecharge: [],
      listSOL: [],
      reverseSchemaBorne: false,
      resultatAllimentation: {},
      allimentation:{
        distance_alimentation_exterieur: 0,
        distance_alimentation_interieur: 0,
        type_Tranche: 0,
        distanceTranche: 0,
      },
      resultatFinal: false,

      // rules
      rules: {
        required: (value) => {
          let valid = false;
          if (typeof value == "number" && Number(value)>=0) {
            valid = true;
          }else if (typeof value == "string" &&  value.toString().length>0) {
            if(typeof Number(value) == "number" && Number(value) != NaN && Number(value)<0){
              valid = false;
            }else{
              valid = true;
            }
          } 
          return valid || "Ce champ est obligatoire."
        },
        siretLength: (v) => !!v && v.length == 14 || "Le siret doit comporter 14 chiffres",
        isString: (value) => !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        isNumber: (v) => /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isNumberOrFloat: (value) => /^[0-9]+(\.\d+)?$/.test(value) || "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isFloat: (v) => /^-?\d*(\.\d+)?$/.test(v) || "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) => {
          if (v && v.length >= 1) {
            return true;
          } else {
            return "Vous devez selectionner aux moins une champ";
          }
        },
        greaterThanZero: (value) => parseFloat(value) > 0 || "La valeur doit être supérieure à zéro.",
        accepteZero: (value) => parseFloat(value) >= 0 || "La valeur doit être supérieure à zéro.",
      },
      devisData:[],
      
    };
  },
  computed:{
    /*computedListTypePointDeRecharge: function () {
      // Extracting type_point_recharge values from listDesBornes (Set used to Distinct values)
      const typePointRechargeSet = new Set(this.listDesBornes.map(borne => borne.type_point_recharge));
      
      const newList = [];
      for (let i = 0; i < this.listTypePointDeRecharge.length; i++) {
        const pointDeRecharge = this.listTypePointDeRecharge[i];
        let disabled = false;
        if (typePointRechargeSet.has(pointDeRecharge.format)) {
          disabled = true;
        }
        newList.push({
          ...pointDeRecharge,
          disabled: disabled,
        });
      }
      return newList;
    }*/
  },
  watch: {
    listDesBornes: {
      deep: true,
      handler(newVal) {
        this.handleListBorneChange();
      },
    },
  },
  async created() {
    await this.initializeMateriel()
  },
  methods: {
    async initializeMateriel() {
      try {
          const response = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}getAllToolsList/`, 
          {
            token: this.$cookies.get("token"),
          });
          if (response?.data.status==true) {
            this.listTypePointDeRecharge = response.data.BorneFormat;
            this.listSOL = response.data.listSol;
          }
        } catch (error) {
          console.log(error)
        }
    },
    async getInfoSociete() {
      if (this.$refs.formSiret.validate()) {
        try {
          this.loadingSiret = true;
          const response = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}getInfoSocieteBorne/`, 
          {
            siret: this.siret, 
            token: this.$cookies.get("token"),
          });
          if (response?.data.status==true) {
            this.listCompteur = response.data.listCompteur;
            this.selectedCompteur = response.data.listCompteur[0].id;
            this.infoSociete = response.data.societe;
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loadingSiret = false;
        }
      }
    },
    async getInfosCompteur() { 
      this.loadingCompteur = true;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getInformationCompteurByIdFaisabilite/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          idCompteur: this.selectedCompteur,
        }),
      }).then((res) => {
        if (Object.keys(res.data.donnerCompteur).length>0) {
          this.infoCompteur = res.data.donnerCompteur;
        }
        this.listDesBornes = res.data.listPointDeRecharge;
        this.allimentation = res.data.allimentation;
      }).catch((error) => {
        console.log(error)
      });
      this.loadingCompteur = false;
    },
    async getListPrixParBorne(e) {
      e.preventDefault();
      if (this.$refs.formBornes.validate() && this.listDesBornes.length>0) {
        try {
          this.loadingListPrixParBorne = true;
          const response = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}getCalculeProposition/`, 
          {
            listPointDeRecharge: this.listDesBornes, 
            allimentation: this.allimentation,
            numCompteur: this.infoCompteur.numCompteur,
            token: this.$cookies.get("token"),
          });
          if(response.data.status == true){
            this.listPrixParBorne = response.data.resultat;
            this.resultatAllimentation = response.data.resultatAllimentation;
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loadingListPrixParBorne = false;
        }
      }
    },
    async getPrice(event, line) {
      event.preventDefault();

      let id = line.id;
      // Access the correct form element within the array
      const form = this.$refs[`formListPrixParBorne${id}`][0];

      if (form && form.validate()) { 
        const response = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}getCalculeFinalTva/`, 
        {
          listPointDeRecharge: line, 
          token: this.$cookies.get("token"),
        });
        if (response.data.status == true) {
          let Line2 = response.data.resultat
          for(var i= 0; i<this.listPrixParBorne.length; i++){
            let item =this.listPrixParBorne [i]
            if(item.id == id){
              item['data'] = Line2['data']
              item['selectedPrice'] = Line2['selectedPrice']
            }
          }
        }
      }
      
    },
    async getAllPrice() {
      this.resultatFinal = false;
      // loop through listPrixParBorne and validate every form by their ref
      let isValid = true;
      for(var i= 0; i<this.listPrixParBorne.length; i++){
        let item = this.listPrixParBorne[i];
        const form = this.$refs[`formListPrixParBorne${item.id}`][0]
        if (form && !form.validate()) {
          // form not valid
          isValid = false;
          break;
        }
      }
      
      if (isValid) {
        const response = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}getCalculeFinalTvaAllBorns/`,{
          listPointDeRecharge: this.listPrixParBorne, 
          resultatAllimentation: this.resultatAllimentation,
          token: this.$cookies.get("token"),
        });
        if (response.data.status == true) {
          this.listPrixParBorne = response.data.resultat
          this.totalResultat = response.data.totalRes
          this.resultatFinal = true
          /*let Line2 = response.data.resultat
          for(var i= 0; i<this.listPrixParBorne.length; i++){
            let item =this.listPrixParBorne [i]
            if(item.id == id){
              item['data'] = Line2['data']
              item['selectedPrice'] = Line2['selectedPrice']
            }
          }*/
        }
      }
      
    },
    async saveResult() {
      const response = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}saveResultDevis/`, 
      {
        listPrixParBorne: this.listPrixParBorne, 
        compteur_id: this.selectedCompteur,
        resultatAllimentation: this.resultatAllimentation,
        token: this.$cookies.get("token"),
        totalResultat: this.totalResultat,
        allimentation:this.allimentation,
      });
      this.devisData = response.data.savedDataDevis;
      this.generatePresentation(); 
    },
    // handle displaying warning for changing compteur or contract
    async handleListBorneChange() {
      // Calculate the sum of 'puissance' values
      const sumPuissance = this.listDesBornes.reduce((total, item) => {
        // Remove commas and convert 'puissance' to a float number
        const obj = this.listTypePointDeRecharge.find((x) => x.format == item.type_point_recharge);
        const puissanceValue = parseFloat(obj.puissance);
        // Add the numeric 'puissance' value to the total
        return total + puissanceValue;
      }, 0);
      const total = sumPuissance + this.infoCompteur.puissanceMaximale;
      this.warningMessageCount += 1;
      if (this.warningMessageCount <= 1) {
        if (total > this.infoCompteur.kva) {
          if (total > this.infoCompteur.puissanceAtteinteMaximale) {
            // sweet alert with confirm and cancel button
            if (!(this.nouveauPDL == "changerContract")) {
              this.$swal({
                title:
                  "La puissance de la borne dépasse la limite maximale. Veuillez envisager une mise à jour de votre contrat.",
                text: "Voulez vous changer le contrat ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
                dangerMode: true,
              }).then((willChange) => {
                if (willChange.isConfirmed) {
                  this.nouveauPDL = "changerContract";
                  this.messageWarning =
                    "La puissance de la borne dépasse la limite maximale. Veuillez envisager une mise à jour de votre contrat.";
                } else {
                  this.nouveauPDL = "non";
                  this.messageWarning = "";
                }
              });
            }
          } else {
            // warning toast
            this.nouveauPDL = "changerCompteur";
            this.messageWarning = "La puissance de la borne dépasse la puissance maximale. Vous devez changer le compteur.";
          }
        } else {
          this.nouveauPDL = "non";
          this.messageWarning = "";
        }
      }
    },
    // add borne to listDesBornes
    addBorne() {
      // Add the new bornes to the "listDesBornes" array
      this.listDesBornes.push({
        nbr_borne: 1,
        type_point_recharge: this.listTypePointDeRecharge[0].format,
        montage: "MURAL",
        ml_interieur: 0,
        ml_exterieur: 0,
        sol: "SOL LEGER + 10M",
        metre_tranche: 0,
      });
    },
    /*addBorne() {
      // Extracting type_point_recharge values from listDesBornes (Set used to Distinct values)
      const typePointRechargeSet = new Set(this.listDesBornes.map(borne => borne.type_point_recharge));
      // Filtering listTypePointDeRecharge based on type_point_recharge not present in listDesBornes
      const filteredPointDeRecharge = this.listTypePointDeRecharge.filter(pointDeRecharge => {
        return !typePointRechargeSet.has(pointDeRecharge.format);
      });
      
      if (filteredPointDeRecharge.length>0) {
        // Add the new bornes to the "listDesBornes" array
        this.listDesBornes.push({
          nbr_borne: 1,
          type_point_recharge: filteredPointDeRecharge[0].format,
          montage: "MURAL",
          ml_interieur: 0,
          ml_exterieur: 0,
          sol: "SOL LEGER + 10M",
          metre_tranche: 0,
        });
      } else {
        this.$swal({
          title: "Vous avez dépassé la valeur maximale autorisée des bornes sur le site.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          dangerMode: true,
        }).then((willChange) => {});
      }
    },*/
    // remove borne from listDesBornes
    removeBorne(index) {
      // Confirm if the user really wants to remove the item
      this.$swal({
        title: "Êtes-vous sûr de vouloir supprimer cette borne ?",
        text: "Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          // Remove the item at the given index from listBorneSelected array
          this.listDesBornes.splice(index, 1);
        }
      });
    },
    capitalizeWords(text) {
        if(!text) return "";
        // Split the text into an array of words
        let words = text.split(' ');
        // Iterate through each word and capitalize the first letter
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        // Join the words back into a single string and return
        return words.join(' ');
    },
    to_fixed2LocaleString(num) {
        if(!isNaN(num) && num !== null && num !== undefined && num !== ""){
            // num is number
            return Number(parseFloat(num).toFixed(2)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() + " €";
        }else{
            // num is text
            if(num !== null && num !== undefined && num !== ""){
                return num
            }
            return ""
        }
    },
    to_fixed2OrText(num) {
        if(!isNaN(num) && num !== null && num !== undefined && num !== ""){
            // num is number
            return Number(parseFloat(num).toFixed(2)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() + "";
        }else{
            // num is text
            if(num !== null && num !== undefined && num !== ""){
                return num
            }
            return ""
        }
    },
    // pdf generation
    async generatePresentation() {
      try {
        this.loadingGeneratingPres = true;
        // Initialize SharedMethods with component-specific data
        const PDFGenerator = new PDFDevisBorneIRVEGenerator(this.devisData);
        await PDFGenerator.generatePresentation();
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingGeneratingPres = false;
      }
    },
  },
};
</script>

<!-- page Style -->
<style lang="scss" scoped>
.wrapper{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #fff;
  min-height: calc(100vh - 65px);
  .search-siret-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 32px;

    .search-siret-input{
      max-width: 450px;
      height: 50px !important;
    }
    .search-siret-btn{
      text-transform: capitalize;
      background-color: #009180;
      color: #fff;
      font-size: 14px;
      font-family: inherit;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 4px;
      padding: 20px 24px;
      height: 55px;
    }
  }
  .card-white{
    width: 100%;
    margin-bottom: 32px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.06),0 2px 4px -2px rgba(16, 24, 40, 0.06);
  }
  .card-info-societe{
    padding: 20px 24px;
    .header-title{
      margin-bottom: 20px;
      border-bottom: 1px solid #EAECF0;
      .title{
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #007991;
        margin-bottom: 10px;
      }
    }
    .content{
      .info-item-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 20px;

        .info-item{
          flex: 1;
          flex-basis: 180px;
        }
        .address{
          min-width: 280px;
        }
      }
      .info-item{
        .info-item-label{
          font-size: 12px;
          font-family: inherit;
          font-weight: 400;
          color: #667085;
          margin-bottom: 8px;
        }
        .info-item-value{
          font-size: 16px;
          font-family: inherit;
          font-weight: 600;
          color: #1E1E1E;
          margin-bottom: 0px;
        }
      }
    }
  }
  .light-table-wrapper{
    width: 100%;
    overflow-x: auto;
    .light-table{
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 0;

      .action{
        width: 50px;
      }

      thead{
        tr{
          background-color: #00b5a0;
          th{
            font-size: 14px;
            font-weight: 500;
            font-family: inherit;
            padding: 15px 15px;
            color:#fff;
            border-top: solid 1px #EAECF0;
            border-bottom: solid 1px #EAECF0;
          }
        }
      }

      tbody{
        tr{
          background-color: #fff;
          td{
            font-size: 14px;
            font-weight: 500;
            font-family: inherit;
            color:#272c36;
            text-align: left;
            padding: 20px 15px;
            vertical-align: middle;
            border-bottom: solid 1px #EAECF0;
          }
        }
      }
    }
  }
  .card-borne-list{
    .header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #009180;
      padding: 20px 20px;
      border-radius: 8px 8px 0px 0px;
      border-bottom: 1px solid #EAECF0;
      .title{
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #fff;
        margin-bottom: 0px;
      }
      .add-borne-btn{
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        padding: 2px 24px;
        color: #344054;
        height: 45px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
      }
    }
    // graph borne here (see bottom scoped styles)
    .footer{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      border-top: solid 1px #EAECF0;
      padding: 20px 20px;
      .valider-btn{
        display: block;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        padding: 2px 24px;
        color: #fff;
        height: 45px;
        background-color: #009180;
        border-radius: 8px;
        border: 1px solid #009180;
        &:disabled{
            background-color: #9cccc6;
            border: 1px solid #9cccc6;
          }
      }
    }
  }
  .card-borne-list-by-price{
    .header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      background-color: #d1e0e8;
      border-radius: 4px;
      border: none;
      .title{
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #1E1E1E;
        margin-bottom: 0px;
      }
    }

    .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
      padding: 0 !important;
    }

    .questions-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 20px;
      // display: flex;
      // flex-direction: column;
      .question{
        display: flex;
        flex-direction: column;
        // flex-direction: row;
        // align-items: center;
        // flex-wrap: wrap;
        // margin-bottom: 15px;
        .question-label{
          font-size: 12px;
          font-family: inherit;
          font-weight: 400;
          color: #667085;
          margin: 0px;
          margin-right: 20px;
        }
        .question-field{
          margin: 0px;
          min-width: 80px;
          max-width: 250px;
        }
      }
    }

    .footer{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px;
      .valider-btn{
        display: block;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        padding: 2px 24px;
        color: #fff;
        height: 45px;
        background-color: #009180;
        border-radius: 8px;
        border: 1px solid #009180;
      }
    }

    .signalitique-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .table-signalitique-wrapper{
        width: 100%;
        flex: 1;
        flex-basis: 400px;
        .table-signalitique{
          width: 100%;
          border-collapse: collapse;
          border-spacing: 0;
          border: 0;
          thead{
            tr{
              background-color: #14324f;
            }
            th,td{
              font-size: 14px;
              color: #fff;
              text-align: center;
              padding: 10px;
              // border-top: solid 1px #EAECF0;
              border-bottom: solid 1px #EAECF0;
            }
          }
          tbody{
            tr{
              background-color: #fff;
            }
            th,td{
              font-size: 14px;
              font-weight: 500;
              font-family: inherit;
              color:#344054;
              text-align: center;
              vertical-align: middle;
              padding: 15px 20px;
              border-bottom: solid 1px #EAECF0;
            }
            .value-title{
              font-size: 14px;
              font-weight: 600;
              color:#00b5a0;
            }
          }
        }
      }
    }
  }
  .card-alimentation{
    .header{
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #d1e0e8;
      padding: 20px 20px;
      border-radius: 8px 8px 0px 0px;
      border-bottom: 1px solid #EAECF0;
      .title{
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #1E1E1E;
        margin-bottom: 0px;
        text-align: center;
      }
    }
    .content-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      .item-card{
        flex: 1;
        flex-basis: 180px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 15px;
        .card-icon{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 54px;
          height: 54px;
          background-color: #31d5ad;
          border-radius: 4px;
          margin: 0px;
          font-size: 32px;
        }
        .item-content{
          .title{
            display: block;
            font-size: 14px !important;
            font-weight: 700;
            color: #00b5ac;
            margin-bottom: 0px;
          }
          .price{
            display: block;
            font-size: 16px;
            font-weight: 700;
            color: #14324f;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .card-tarif-signalitique{
    .header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: #009180;
      padding: 20px 20px;
      border-radius: 8px 8px 0px 0px;
      .title{
        font-size: 18px;
        font-family: inherit;
        font-weight: 500;
        color: #fff;
        margin-bottom: 0px;
      }
      .valider-btn{
        text-transform: capitalize;
        background-color: #14324f;
        color: #fff;
        font-size: 12px;
        font-family: inherit;
        font-weight: 400;
        letter-spacing: 1px;
        border-radius: 4px;
        padding: 20px 24px;
        height: 48px;
      }
    }
    .table-signalitique-wrapper{
      overflow-x: auto;
    }
  }
  .result-footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    padding-inline: 0px;
    padding-block: 20px;
    margin-bottom: 40px;
    .generate-btn{
      text-transform: capitalize;
      background-color: #607d8b;
      color: #fff;
      font-size: 12px;
      font-family: inherit;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 4px;
      padding: 20px 24px;
      margin-right: 15px;
      height: 48px;
    }
    .save-btn{
      text-transform: capitalize;
      background-color: #009180;
      color: #fff;
      font-size: 12px;
      font-family: inherit;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 4px;
      padding: 20px 24px;
      height: 48px;
    }
  }
}


/* loader */
.loader {
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #ffffff;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}
</style>

<!-- borne graph -->
<style scoped>
.imgs-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.tgbt-img-wrapper {
  display: block;
}
.tgbt-img {
  width: 100px;
  height: 100px;
}
.tdirve-img-wrapper {
  display: block;
}
.tdirve-img {
  width: 100px;
  height: 100px;
}
.tgbt-img-wrapper .label, .tdirve-img-wrapper .label{
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.line {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #8aa7b4;
}
.input-tgbt-tdirve {
  position: absolute;
  top: -60px;
  left: 30%;
  transform: translateX(-20%);
  /*width: 50%;
  max-width: 200px;
  height: 56px;*/
}
/************ borne ************* */
.borne-wrapper {
  display: flex;
  flex-direction: column;
}
.nbr-borne-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.nbr-borne {
  max-width: 200px;
  height: 56px;
}
.borne-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 80px;
  padding: 15px;
  border-radius: 6px;
  background-color: rgb(179, 206, 206);
}
.borne {
  flex: 1;
  min-width: 200px;
  max-width: 350px;
  min-height: 100px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(182, 182, 182, 0.75);
}
.borne-btn-close {
  color: rgb(221, 113, 113);
  cursor: pointer;
}

.borne-btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #dae6e4;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 15px;
  height: 54px !important;
}

.borne-btn-add-icon {
  font-size: 32px;
  color: #4f6772;
  padding: 0px;
}

.borne-counter {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif;
  color: #414242;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid #667f8b;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
}
.borne-counter::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 3px;
  background-color: #8aa7b4;
}
.borne-counter::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 3px;
  background-color: #8aa7b4;
}
</style>

